import { FIRESTORE } from "constants/firestore";
import { BillStatus } from "types/billing";
import { FinishStatus } from "types/transfer";
import moment from "moment";

export const getModuleTransferWithoutArtInvoice = (
  art: string | undefined,
  startDate: Date,
  endDate: Date,
  keyword: string | undefined,
  idAccident: string | undefined
) => {
  let coll;
  coll = FIRESTORE.COLLECTION_MODULES_TRANSFER()
    .where("sale.billed", "==", BillStatus.No)
    .where("status.finished_status", "in", [0, 2]);
  //coll =coll.where("reject.status", "!=", true)

  if (idAccident) coll = coll.where("art.accident", "==", idAccident);
  if (art === "MekXauxMVPd1ayCGpIwWjg7j6Z73") {
    coll = coll
      .where("art.id", "==", art)
      .where(
        "destination.time",
        ">=",
        moment(new Date("12/04/2022 23:59")).toDate()
      );
  } else {
    coll = coll.where("art.id", "==", art);
  }

  if (keyword) coll = coll.where("keywords", "array-contains", keyword);

  coll = coll
    .where("destination.time", ">=", startDate)
    .where("destination.time", "<=", endDate);

  return coll
    .orderBy("destination.time", "desc")
    .orderBy("origin.time", "desc")
    .orderBy("destination.geohash", "desc");
};

export const getArtInvoices = (
  art: string | undefined,
  startDate: Date,
  endDate: Date,
  keyword: string | undefined
) => {
  let coll;
  coll = FIRESTORE.COLLECTION_ARTS_INVOICES();

  if (art) coll = coll.where("art", "==", art);

  if (keyword) coll = coll.where("keywords", "array-contains", keyword);

  coll = coll
    .where("creation_date", ">=", startDate)
    .where("creation_date", "<=", endDate);

  return coll.orderBy("creation_date", "desc");
};

export const getArtInvoicesNotCanceled = (
  art: string | undefined,
  startDate: Date,
  endDate: Date,
  keyword: string | undefined
) => {
  let coll;
  coll = FIRESTORE.COLLECTION_ARTS_INVOICES().where("canceled", "==", false);

  if (art) coll = coll.where("art", "==", art);

  if (keyword) coll = coll.where("voucher_number", "==", Number(keyword));

  coll = coll
    .where("creation_date", ">=", startDate)
    .where("creation_date", "<=", endDate);

  return coll.orderBy("creation_date", "desc");
};

export const getArtInvoicesCanceled = (
  art: string | undefined,
  startDate: Date,
  endDate: Date,
  keyword: string | undefined
) => {
  let coll;
  coll = FIRESTORE.COLLECTION_ARTS_INVOICES().where("canceled", "==", true);

  if (art) coll = coll.where("art", "==", art);

  if (keyword)
    coll = coll.where("deleted_vouche_number", "==", Number(keyword));

  coll = coll
    .where("canceled_date", ">=", startDate)
    .where("canceled_date", "<=", endDate);
  return coll.orderBy("canceled_date", "desc");
  // return coll.orderBy("creation_date", "desc");
};

export const getArtInvoicesWithoutPayment = (
  art: string | undefined,
  startDate: Date,
  endDate: Date,
  keyword: string | undefined
) => {
  let coll;
  coll = FIRESTORE.COLLECTION_ARTS_INVOICES().where(
    "payment_order",
    "==",
    null
  );

  if (art) coll = coll.where("art", "==", art);

  if (keyword) coll = coll.where("keywords", "array-contains", keyword);

  coll = coll
    .where("creation_date", ">=", startDate)
    .where("creation_date", "<=", endDate);

  return coll.orderBy("creation_date", "desc");
};

export const getArtInvoicesWithoutPaymentNotCanceled = (
  art: string | undefined,
  startDate: Date,
  endDate: Date,
  keyword: string | undefined
) => {
  let coll;
  coll = FIRESTORE.COLLECTION_ARTS_INVOICES().where(
    "payment_order",
    "==",
    null
  );
  coll = coll.where("canceled", "==", false);
  if (art) coll = coll.where("art", "==", art);

  if (keyword) coll = coll.where("keywords", "array-contains", keyword);

  coll = coll
    .where("creation_date", ">=", startDate)
    .where("creation_date", "<=", endDate);

  return coll.orderBy("creation_date", "desc");
};

export const getLiquidationInvoice = (moduleId: string) => {
  let coll;
  coll = FIRESTORE.COLLECTION_ARTS_INVOICES().where(
    "keywords",
    "array-contains",
    moduleId
  );

  return coll.orderBy("creation_date", "desc");
};

export const getArtInvoicesDebited = (
  art: string | undefined,
  startDate: Date,
  endDate: Date,
  keyword: string | undefined
) => {
  let coll;
  coll = FIRESTORE.COLLECTION_ARTS_INVOICES();

  if (art) coll = coll.where("art", "==", art);

  if (keyword) coll = coll.where("debit_voucher_number", "==", Number(keyword));

  coll = coll
    .where("debit_date", ">=", startDate)
    .where("debit_date", "<=", endDate);
  return coll.orderBy("debit_date", "desc");
  // return coll.orderBy("creation_date", "desc");
};

export const getArtInvoicesWithError = (
  art: string | undefined,
  startDate: Date,
  endDate: Date,
  keyword: string | undefined
) => {
  let coll;
  coll = FIRESTORE.COLLECTION_ARTS_INVOICES()

    // .where("creation_date", ">=", startDate)
    // .where("creation_date", "<=", endDate)
    .where("respuesta_api.status", "!=", 200);

  if (art) coll = coll.where("art", "==", art);

  //if (keyword) coll = coll.where("debit_voucher_number", "==", Number(keyword));

  return coll.orderBy("respuesta_api.status", "desc");
  // return coll.orderBy("creation_date", "desc");
};

export const getArtModulesNotForBill = (
  art: string | undefined,
  startDate: Date,
  endDate: Date,
  keyword: string | undefined
) => {
  let coll;
  coll = FIRESTORE.COLLECTION_MODULES_TRANSFER().where(
    "sale.billed",
    "==",
    BillStatus["No facturar"]
  );

  if (art) coll = coll.where("art", "==", art);

  if (keyword) coll = coll.where("art.accident", "==", keyword);

  coll = coll
    .where("origin.time", ">=", startDate)
    .where("origin.time", "<=", endDate);
  return coll.orderBy("origin.time", "desc");
  // return coll.orderBy("creation_date", "desc");
};

export const getArtModulesNonCompliance = (
  art: string | undefined,
  startDate: Date,
  endDate: Date,
  keyword: string | undefined
) => {
  let coll;
  coll = FIRESTORE.COLLECTION_MODULES_TRANSFER().where(
    "status.finished_status",
    "==",
    FinishStatus.Incumplimiento
  );

  if (art) coll = coll.where("art", "==", art);

  if (keyword) coll = coll.where("art.accident", "==", keyword);

  coll = coll
    .where("origin.time", ">=", startDate)
    .where("origin.time", "<=", endDate);
  return coll.orderBy("origin.time", "desc");
  // return coll.orderBy("creation_date", "desc");
};
