import firebase from "firebase/app";
import React, { useState, useEffect } from "react";
import { Alert, Button, Col, Row, Accordion, Card } from "react-bootstrap";
import {
  BillStatus,
  TIPOS_COMPROBANTES,
  ChallengeBilling,
} from "types/billing";
import {
  FbDocument,
  TransferStage,
  FinishStatus,
  ScheduledTransfer,
  FbGeoPoint,
} from "types/transfer";
import { HistoryModal } from "./modals/history";
import { EditModal } from "./modals/edit";
import { BillModal, CancelBillModal, BillModalRevision } from "./modals/bill";
import { VoucherButton } from ".";
import { FIRESTORE } from "constants/firestore";
import { AsyncButton, ButtonWithConfirmation, Link } from "components/styles";
import { PersonalInformation, TaxableEntity, UserType } from "types/user-data";
import { useProfile } from "redux/app";
import * as fs from "file-saver";
import moment from "moment";
import { useDatabaseDocref, useDbDocument } from "controllers/hooks";
import { getPersonalInformationColDoc } from "controllers/user/info/personal";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import { ReportModal } from "./modals/report";
import { DelayModal } from "./modals/delay";
import { BillCabifyModal } from "./modals/bill-cabify";
import { EditStateModal } from "./modals/editState";
import ROUTES from "constants/routes";
import { TransferEventMotive } from "types/report";

export const Header = ({
  transfer,
  refTransfer,
  uidDriver,
  idModule,
}: {
  transfer: ScheduledTransfer;
  refTransfer: FbDocument<ScheduledTransfer>;
  uidDriver: string;
  idModule?: string;
}) => {
  const userType = useProfile().type;
  const driverData = useDbDocument<PersonalInformation>(
    ...getPersonalInformationColDoc(uidDriver)
  );
  const module = useDatabaseDocref(
    FIRESTORE.DOCUMENT_MODULE_TRANSFER(transfer.module.id)
  );
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showBillModal, setShowBillModal] = useState(false);
  const [showBillModalRevision, setShowBillModalRevision] = useState(false);
  const [showCancelBillModal, setShowCancelBillModal] = useState(false);
  const [showBillingCabifyModal, setShowBillingCabifyModal] = useState(false);
  const [showChangeStateModal, setShowChangeStateModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [showDelayedModal, setShowDelayedModal] = useState(false);
  const [dateGps, setDateGps] = useState("");
  const [gps, setGps] = useState<FbGeoPoint>();
  const [gestorando, setGestorando] = useState<Boolean>(false);
  const [filename, setFilename] = useState<string>("");
  const [file_base64, setfile_base64] = useState<string>("");
  const [idModulePool, setIdModulePool] = useState<string>("");

  let isReturn = module?.status.doing_return;

  const revertNegative = async () => {
    const data = { idTransfer: refTransfer.id, uidDriver };
    await CALLABLE_FUNCTIONS.REVERT_NEGATIVE(data);
  };

  const coordinateSchedule = async () => {
    const data = {
      idTransfer: refTransfer.id,
      motive: TransferEventMotive["Conductor coordina horario con paciente"],
      uid: uidDriver,
    };

    await CALLABLE_FUNCTIONS.ADMIN_COORDINATE_SCHEDULE(data);
  };

  const deleteBill = async (invoiceId: string) =>
    await CALLABLE_FUNCTIONS.DELETE_TRANSFER_BILL({ idBill: invoiceId });

  useEffect(() => {
    if (uidDriver !== undefined) {
      FIRESTORE.DOCUMENT_USER(uidDriver)
        .get()
        .then((doc) => doc.data())
        .then((data) => {
          const lastGpsDate = moment(data?.gps?.date.toDate()).format(
            "DD/MM HH:mm"
          );
          setDateGps(lastGpsDate);
          setGps(data?.gps?.geopoint);
          setGestorando(data?.delegationGestorando === true ? true : false);
        });
    }
    /*  if (transfer.pool![0] !== undefined) {
      transfer
        .pool![0].refTransfer.get()
        .then((doc) => doc.data())
        .then((data) => {
          const moduleId = data!.module.id;
          setIdModulePool(moduleId);
        });
    } */
  }, []);
  const reset = async () => {
    if (transfer!.sale!.bill_info!) {
      if (transfer!.sale!.bill_info!.ref) {
        await FIRESTORE.DOCUMENT_SCHEDULED_TRANSFER(
          uidDriver,
          refTransfer.id
        ).update({
          "sale.billed_from_driver": BillStatus.Si,
          "sale.billed_from_driver_comment":
            firebase.firestore.FieldValue.delete(),
        });
      } else {
        await FIRESTORE.DOCUMENT_SCHEDULED_TRANSFER(
          uidDriver,
          refTransfer.id
        ).update({
          "sale.billed_from_driver": BillStatus.No,
          "sale.billed_from_driver_comment":
            firebase.firestore.FieldValue.delete(),
        });
      }
    } else {
      await FIRESTORE.DOCUMENT_SCHEDULED_TRANSFER(
        uidDriver,
        refTransfer.id
      ).update({
        "sale.billed_from_driver": BillStatus.No,
        "sale.billed_from_driver_comment":
          firebase.firestore.FieldValue.delete(),
      });
    }
  };

  const forceStage = async (tramo: string) => {
    CALLABLE_FUNCTIONS.ADMIN_FORCE_STAGES_CABIFY(
      refTransfer.id,
      uidDriver,
      tramo
    );
  };
  const fixPrice = async () => {
    const data = { refTransfer: refTransfer.id, uidDriver: uidDriver };
    await CALLABLE_FUNCTIONS.ADMIN_FIX_PRICE(data);
  };
  const forceEnd = async () => {
    CALLABLE_FUNCTIONS.ADMIN_FORCE_END(uidDriver);
  };

  const forceCancelReport = async () => {
    CALLABLE_FUNCTIONS.REPORT_CANCEL(refTransfer.id, uidDriver);
  };

  const devolverActive = async () => {
    const data = {
      refTransfer: refTransfer.id,
      uidDriver: uidDriver,
      isDriver: false,
    };
    console.log(data);
    CALLABLE_FUNCTIONS.ADMIN_RETURN_ACTIVE(data);
  };
  const prioridadDeRetorno = async () => {
    await FIRESTORE.DOCUMENT_MODULE_TRANSFER(transfer.module.id).update({
      priority: uidDriver,
    });
  };
  const quitarPrioridadDeRetorno = async () => {
    await FIRESTORE.DOCUMENT_MODULE_TRANSFER(transfer.module.id).update({
      priority: firebase.firestore.FieldValue.delete(),
    });
  };
  if (filename !== "" && file_base64 !== "") {
    const linkSource = `data:application/pdf;base64,${file_base64}`;
    const downloadLink = document.createElement("a");
    const fileName = filename;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }
  const downloadPDF = async () => {
    await CALLABLE_FUNCTIONS.DOWLOAD_PDF_GESTORANDO({
      idBill: transfer.sale?.bill_info!.ref.id,
    }).then((d) => {
      setFilename(d.data.filename);
      setfile_base64(d.data.file_base64);
    });
  };

  return (
    <>
      <HistoryModal
        transfer={transfer}
        show={showHistoryModal}
        handleClose={() => setShowHistoryModal(false)}
      />
      <EditModal
        idTransfer={refTransfer.id}
        idDriver={uidDriver}
        cabify={
          driverData
            ? driverData!.name == "Cabify" || driverData!.surname == "Villasuso"
              ? true
              : false
            : false
        }
        show={showEditModal}
        handleClose={() => setShowEditModal(false)}
        finished={transfer.status.stage === TransferStage.finished}
        bill={transfer.sale?.billed_from_driver === BillStatus.Si}
      />
      <BillModal
        idTransfer={refTransfer.id}
        uidDriver={uidDriver}
        show={showBillModal}
        handleClose={() => setShowBillModal(false)}
      />
      <BillModalRevision
        idTransfer={refTransfer.id}
        uidDriver={uidDriver}
        show={showBillModalRevision}
        handleClose={() => setShowBillModalRevision(false)}
      />
      <CancelBillModal
        idTransfer={refTransfer.id}
        uidDriver={uidDriver}
        show={showCancelBillModal}
        handleClose={() => setShowCancelBillModal(false)}
      />
      <BillCabifyModal
        idModule={idModule}
        idTransfer={refTransfer.id}
        uidDriver={uidDriver}
        show={showBillingCabifyModal}
        handleClose={() => setShowBillingCabifyModal(false)}
      />
      <EditStateModal
        idTransfer={refTransfer.id}
        uidDriver={uidDriver}
        show={showChangeStateModal}
        handleClose={() => setShowChangeStateModal(false)}
      />
      <ReportModal
        idTransfer={refTransfer.id}
        idDriver={uidDriver}
        show={showReportModal}
        handleClose={() => setShowReportModal(false)}
      />
      <DelayModal
        idTransfer={refTransfer.id}
        idDriver={uidDriver}
        show={showDelayedModal}
        handleClose={() => setShowDelayedModal(false)}
      />

      <Row>
        <Col>
          {transfer.status.active_report! && (
            <Button
              className="pull-right"
              style={{ margin: "0 10px 5px" }}
              onClick={() => forceCancelReport()}
            >
              Borrar Informe
            </Button>
          )}
          {userType === UserType.admin &&
            gestorando === false &&
            transfer.sale?.bill_info?.pdf === undefined &&
            transfer.sale?.billed_from_driver !==
              BillStatus["Error al facturar"] &&
            transfer.sale?.billed_from_driver !== BillStatus["Si"] &&
            transfer.sale?.billed_from_driver !== BillStatus["No facturar"] &&
            transfer.status.stage === TransferStage.finished &&
            !transfer.farFromOrigin &&
            transfer.status.finished_status !== FinishStatus.Cancelado &&
            (!transfer.challenge ||
              transfer.challenge.to_be_billed ===
                ChallengeBilling["Facturar normal"]) && (
              <Button
                className="pull-right"
                style={{ margin: "0 10px 5px" }}
                onClick={() => setShowBillModal(true)}
              >
                Forzar Factura Compra
              </Button>
            )}
          {transfer.farFromOrigin &&
            transfer.sale?.billed_from_driver === 0 && (
              <Button
                className="pull-right"
                style={{ margin: "0 10px 5px" }}
                onClick={() => setShowBillModalRevision(true)}
              >
                Revisar Facturación
              </Button>
            )}
          {userType === UserType.admin &&
            gestorando === false &&
            transfer.sale?.bill_info?.pdf === undefined &&
            transfer.sale?.billed_from_driver !==
              BillStatus["Error al facturar"] &&
            transfer.sale?.billed_from_driver !== BillStatus["Si"] &&
            transfer.sale?.billed_from_driver !== BillStatus["No facturar"] &&
            transfer.status.stage === TransferStage.finished &&
            transfer.status.finished_status !== FinishStatus.Cancelado &&
            transfer.challenge?.to_be_billed ===
              ChallengeBilling["Facturar como Desafío"] && (
              <Button
                className="pull-right"
                style={{ margin: "0 10px 5px" }}
                onClick={() => setShowBillModal(true)}
              >
                Forzar Factura DESAFIO
              </Button>
            )}
          {userType === UserType.admin &&
            transfer.sale?.bill_info?.pdf === undefined &&
            transfer.sale?.billed_from_driver !== BillStatus["Si"] &&
            transfer.sale?.billed_from_driver !== BillStatus["No facturar"] &&
            transfer.status.stage === TransferStage.finished &&
            transfer.status.finished_status !== FinishStatus.Cancelado &&
            transfer.sale?.billed_from_driver !== 6 && (
              <Button
                variant="danger"
                className="pull-right"
                style={{ margin: "0 10px 5px" }}
                onClick={() => setShowCancelBillModal(true)}
              >
                NO Facturar Compra
              </Button>
            )}
          {userType === UserType.admin &&
          driverData &&
          transfer.status.stage !== TransferStage.finished &&
          (driverData!.name == "Cabify" ||
            driverData!.surname == "Villasuso") ? (
            transfer.status.stage === TransferStage.scheduled && !isReturn ? (
              <Button
                className="pull-right"
                style={{ margin: "0 10px 5px" }}
                onClick={() => forceStage("ida1")}
              >
                Iniciar
              </Button>
            ) : transfer.status.stage ===
              TransferStage.going_to_meeting_point ? (
              <Button
                className="pull-right"
                style={{ margin: "0 10px 5px" }}
                onClick={() => forceStage("ida2")}
              >
                Viaje en curso
              </Button>
            ) : transfer.status.stage ===
              TransferStage.origin_to_destination ? (
              <Button
                className="pull-right"
                style={{ margin: "0 10px 5px" }}
                onClick={() => forceStage("ida")}
              >
                Terminar IDA
              </Button>
            ) : (transfer.status.stage === TransferStage.scheduled &&
                isReturn) ||
              transfer.status.stage === TransferStage.return_requested ? (
              <Button
                className="pull-right"
                style={{ margin: "0 10px 5px" }}
                onClick={() => forceStage("vuelta1")}
              >
                Iniciar Retorno
              </Button>
            ) : transfer.status.stage ===
              TransferStage.going_to_meeting_point_return ? (
              <Button
                className="pull-right"
                style={{ margin: "0 10px 5px" }}
                onClick={() => forceStage("vuelta2")}
              >
                Retorno en curso
              </Button>
            ) : transfer.status.stage ===
              TransferStage.destination_to_origin ? (
              <Button
                className="pull-right"
                style={{ margin: "0 10px 5px" }}
                onClick={() => {
                  forceStage("vuelta");
                }}
              >
                Terminar VUELTA
              </Button>
            ) : (
              ""
            )
          ) : (
            ""
          )}
          {userType === UserType.admin &&
            gestorando === false &&
            transfer &&
            transfer.sale &&
            transfer.sale.bill_info &&
            transfer.status.stage === TransferStage.finished &&
            transfer.sale.bill_info.ref &&
            transfer.sale?.bill_info?.pdf &&
            transfer.sale.billed_from_driver === BillStatus.Si && (
              <ButtonWithConfirmation
                className="pull-right"
                onClick={() => deleteBill(transfer.sale!.bill_info!.ref.id)}
                style={{ marginLeft: 10 }}
                variant="danger"
                confirmText="¿Está seguro que quiere crear una nota de crébito?"
              >
                Hacer nota de crédito
              </ButtonWithConfirmation>
            )}
          {uidDriver === "zdrEGB4N9dQuGBItxAqWlmTcVtn2" &&
          transfer.sale?.voucher ? (
            <Button
              variant="secondary"
              onClick={() => setShowBillingCabifyModal(true)}
              className="pull-right"
            >
              TRASLADO DE CABIFY
            </Button>
          ) : transfer.sale?.bill_info?.pdf &&
            userType === UserType.admin &&
            transfer.sale.bill_info.pdf.includes("invoices") ? (
            <VoucherButton src={transfer.sale?.bill_info?.pdf} text="Factura" />
          ) : transfer.sale?.bill_info?.pdf &&
            userType === UserType.admin &&
            !transfer.sale.bill_info.pdf.includes("invoices") ? (
            <AsyncButton
              className="pull-right"
              variant="info"
              onClick={downloadPDF}
            >
              Descargar PDF
            </AsyncButton>
          ) : transfer.sale?.bill_info?.missing === true ? (
            <Button variant="secondary" disabled className="pull-right">
              FACTURADO SIN PDF
            </Button>
          ) : userType === UserType.admin &&
            driverData &&
            transfer.status.stage == TransferStage.finished &&
            transfer.sale?.billed_from_driver !== BillStatus.Facturando ? (
            <Button
              className="pull-right"
              style={{ margin: "0 10px 5px" }}
              onClick={() => setShowBillingCabifyModal(true)}
            >
              Cargar datos de facturación
            </Button>
          ) : (
            ""
          )}
          {/* {uidDriver === "zdrEGB4N9dQuGBItxAqWlmTcVtn2" && (
            <AsyncButton variant="danger">CANCELAR CABIFY</AsyncButton>
          )} */}
          {/* {userType === UserType.admin &&
          driverData &&
          transfer.status.stage == TransferStage.finished ? (
            <Button
              className="pull-right"
              style={{ margin: "0 10px 5px" }}
              onClick={() => setShowBillingCabifyModal(true)}
            >
              Cargar datos de facturación
            </Button>
          ) : (
            ""
          )} */}
          {userType === UserType.admin &&
            driverData &&
            transfer.status.stage !== TransferStage.finished &&
            transfer.status.stage !== TransferStage.scheduled && (
              /* (driverData!.name == "Cabify" ||
              driverData!.surname == "Villasuso")  &&*/ <Button
                className="pull-right"
                style={{ margin: "0 10px 5px" }}
                onClick={() => setShowReportModal(true)}
              >
                Informar
              </Button>
            )}
          {userType === UserType.admin &&
            driverData &&
            transfer.status.stage !== TransferStage.finished &&
            transfer.status.stage == TransferStage.scheduled &&
            (driverData!.name == "Cabify" ||
              driverData!.surname == "Villasuso") && (
              <Button
                className="pull-right"
                style={{ margin: "0 10px 5px" }}
                onClick={() => setShowDelayedModal(true)}
              >
                Viaje Demorado
              </Button>
            )}
          {userType === UserType.admin && (
            <Button
              className="pull-right"
              style={{ margin: "0 10px 5px" }}
              onClick={() => setShowEditModal(true)}
            >
              Editar
            </Button>
          )}
          {userType === UserType.admin &&
          transfer.sale?.billed_from_driver === BillStatus.Si ? (
            <Button
              className="pull-right"
              style={{ margin: "0 10px 5px" }}
              onClick={() => {
                generateTxt(transfer, uidDriver);
              }}
            >
              BAS
            </Button>
          ) : null}
          <Button
            className="pull-right"
            variant="warning"
            style={{ margin: "0 10px 5px" }}
            onClick={() => setShowHistoryModal(true)}
          >
            Historial
          </Button>
          {transfer.status.stage !== 9 &&
          transfer.status.stage !== 12 &&
          moment(new Date()).format("MM/DD/YY") >=
            moment(transfer.origin.time.toDate()).format("MM/DD/YY") ? (
            <Button
              className="pull-right"
              style={{ margin: "0 10px 5px" }}
              variant="primary"
              onClick={() => setShowChangeStateModal(true)}
            >
              Estado
            </Button>
          ) : null}
          {transfer.status.stage !== 9 &&
          transfer.status.stage !== 0 &&
          transfer.status.stage !== 12 ? (
            <Button
              variant="success"
              className="pull-right"
              style={{ margin: "0 10px 5px" }}
              onClick={() => {
                devolverActive();
              }}
            >
              Devolver active
            </Button>
          ) : null}
          {transfer.status.stage === 9 &&
          transfer.status.finished_status === 0 &&
          module?.status.doing_return === true &&
          module.transfers.past?.return === undefined &&
          module.priority === undefined &&
          uidDriver !== "zdrEGB4N9dQuGBItxAqWlmTcVtn2" ? (
            <Button
              variant="success"
              className="pull-right"
              style={{ margin: "0 10px 5px" }}
              onClick={() => {
                prioridadDeRetorno();
              }}
            >
              Prioridad de retorno
            </Button>
          ) : null}
          {transfer.status.stage === TransferStage.finished &&
          transfer.status.finished_status === 0 &&
          module?.status.doing_return === true &&
          module.transfers.past?.return === undefined &&
          module.priority !== undefined &&
          uidDriver !== "zdrEGB4N9dQuGBItxAqWlmTcVtn2" ? (
            <Button
              variant="danger"
              className="pull-right"
              style={{ margin: "0 10px 5px" }}
              onClick={() => {
                quitarPrioridadDeRetorno();
              }}
            >
              Quitar Prioridad de Retorno
            </Button>
          ) : null}
          <AsyncButton
            variant="success"
            className="pull-right"
            style={{ margin: "0 10px 5px" }}
            onClick={fixPrice}
          >
            Corregir precio
          </AsyncButton>
          {transfer.sale?.voucher &&
            transfer.sale.voucher.map((v, index) => (
              <VoucherButton
                src={v}
                key={v}
                text={
                  `Voucher` + (index > 0 ? " " + (index + 1).toString() : "")
                }
              />
            ))}
          {userType === UserType.admin &&
            transfer.status.stage !== TransferStage.searching_driver &&
            transfer.status.stage !== TransferStage.searching_driver_return &&
            transfer.status.stage !== TransferStage.searching_driver_incident &&
            gps && (
              <a
                target="_blank"
                href={ROUTES.GOOGLE_MAPS_SEARCH_COORDINATES(gps)}
              >
                <Button variant="warning">Ubicación {dateGps}</Button>
              </a>
            )}
          <AsyncButton
            onClick={async () =>
              await FIRESTORE.DOCUMENT_USER(uidDriver).update({
                active_transfer: firebase.firestore.FieldValue.delete(),
              })
            }
          >
            Quitar active
          </AsyncButton>
          {(transfer.status.stage === TransferStage.scheduled &&
            uidDriver !== "GjP1gJT9T1eK0FGe2kfx6o4T80J2" &&
            transfer.coordinated_schedule !== true) ||
          (transfer.status.stage === TransferStage.going_to_meeting_point &&
            transfer.coordinated_schedule !== true &&
            uidDriver !== "GjP1gJT9T1eK0FGe2kfx6o4T80J2") ? (
            <AsyncButton variant="success" onClick={coordinateSchedule}>
              Coordinó horario con paciente
            </AsyncButton>
          ) : (
            ""
          )}
          {transfer.status.finished_status === FinishStatus.Negativo && (
            <AsyncButton onClick={revertNegative}>Anular Negativo</AsyncButton>
          )}
          {/*  {transfer.pool[0] !== undefined && (
            <Link to={ROUTES.ART_TRANSFER_VIEW(idModulePool)}>
              <Button variant="warning">Traslado Pool</Button>
            </Link>
          )} */}
          {/* {transfer.sale?.bill_info?.pdf && userType === UserType.admin && (
            <VoucherButton src={transfer.sale?.bill_info?.pdf} text="Factura" />
          )} */}
          {transfer.sale?.billed_from_driver === 6 ? (
            <Button
              variant="warning"
              className="pull-right"
              style={{ margin: "0 10px 5px" }}
            >
              Facturacion en Gestorando
            </Button>
          ) : null}
        </Col>
      </Row>
      {userType === UserType.admin && (
        <Row style={{ marginTop: 10 }}>
          <Col>
            {transfer.sale?.billed_from_driver ===
              BillStatus["Error al facturar"] && (
              <Accordion>
                <Alert variant="danger" style={{ overflow: "auto" }}>
                  <span>
                    <b>Hubo un error al facturar automáticamente el módulo</b>
                  </span>
                  <AsyncButton
                    onClick={reset}
                    variant="warning"
                    className="pull-right"
                  >
                    Reset Error
                  </AsyncButton>
                  <Accordion.Toggle
                    as={Button}
                    style={{ fontSize: 14, margin: 5 }}
                    variant="info"
                    eventKey="0"
                    className="pull-right"
                  >
                    Más Info
                  </Accordion.Toggle>

                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      {transfer.sale?.billed_from_driver_comment}
                    </Card.Body>
                  </Accordion.Collapse>
                </Alert>
              </Accordion>
            )}
          </Col>
        </Row>
      )}
    </>
  );
};

const generateTxt = async (trans: ScheduledTransfer, uid: string) => {
  const bill = (await trans.sale!.bill_info!.ref.get()).data();
  const driver = (await FIRESTORE.DOCUMENT_USER(uid).get()).data();

  const data = `[REGISTRO]\nFactura compra\n${moment(
    bill?.creation_date.toDate()
  ).format("DD/MM/YY")}|${
    bill!.type === TIPOS_COMPROBANTES["FACTURA A"]
      ? "MA"
      : bill!.type === TIPOS_COMPROBANTES["FACTURA B"]
      ? "MB"
      : bill!.type === TIPOS_COMPROBANTES["FACTURA C"]
      ? "MC"
      : "MM"
  }|${String(bill?.point_of_sale).padStart(5, "0")}|${String(
    bill?.voucher_number
  ).padStart(8, "0")}|${
    driver?.user_code
      ? "C" + String(driver?.user_code).padStart(5, "0")
      : "no tiene"
  }|${driver?.name} ${driver?.surname}||C|001|${
    bill?.tax_entity === 0 ? "I" : "M"
  }|${String(bill?.ImpNeto.toFixed(2))}|||||${String(
    bill?.ImpTotal.toFixed(2)
  )}||L|L|||||||006|N||20000|||||||||||||||||||||||\nITEM|PRECIO VIAJE|||N|||${String(
    bill?.amounts?.transfer.toFixed(2)
  )}||${String(bill?.amounts?.transfer.toFixed(2))}|${String(
    bill?.amounts?.transfer.toFixed(2)
  )}||||||||||||||||||||||||||||||||||||\n${
    bill?.amounts?.tolls
      ? `ITEM|PEAJES|||N|||${String(bill?.amounts?.tolls.toFixed(2))}||${String(
          bill?.amounts?.tolls.toFixed(2)
        )}|${String(
          bill?.amounts?.tolls.toFixed(2)
        )}||||||||||||||||||||||||||||||||||||\n`
      : ""
  }${
    bill?.amounts?.tolls
      ? `ITEM|ESPERA|||N|||${String(
          bill?.amounts?.wait_request.toFixed(2)
        )}||${String(bill?.amounts?.wait_request.toFixed(2))}|${String(
          bill?.amounts?.wait_request.toFixed(2)
        )}||||||||||||||||||||||||||||||||||||\n`
      : ""
  }${
    bill?.amounts?.additional_K
      ? `ITEM|ADICIONAL SIN PASAJERO A BORDO|||N|||${String(
          bill?.amounts?.additional_K.toFixed(2)
        )}||${String(bill?.amounts?.additional_K.toFixed(2))}|${String(
          bill?.amounts?.additional_K.toFixed(2)
        )}||||||||||||||||||||||||||||||||||||\n`
      : ""
  }${
    bill?.amounts?.additional_5M
      ? `ITEM|ADICIONAL 5M|||N|||${String(
          bill?.amounts?.additional_5M.toFixed(2)
        )}||${String(bill?.amounts?.additional_5M.toFixed(2))}|${String(
          bill?.amounts?.additional_5M.toFixed(2)
        )}||||||||||||||||||||||||||||||||||||\n`
      : ""
  }${
    bill?.amounts?.additional_C
      ? `ITEM|ADICIONAL C|||N|||${String(
          bill?.amounts?.additional_C.toFixed(2)
        )}||${String(bill?.amounts?.additional_C.toFixed(2))}|${String(
          bill?.amounts?.additional_C.toFixed(2)
        )}||||||||||||||||||||||||||||||||||||\n`
      : ""
  }${
    bill?.amounts?.additional_E
      ? `ITEM|ADICIONAL E|||N|||${String(
          bill?.amounts?.additional_E.toFixed(2)
        )}||${String(bill?.amounts?.additional_E.toFixed(2))}|${String(
          bill?.amounts?.additional_E.toFixed(2)
        )}||||||||||||||||||||||||||||||||||||\n`
      : ""
  }${
    bill?.amounts?.additional_G
      ? `ITEM|ADICIONAL G|||N|||${String(
          bill?.amounts?.additional_G.toFixed(2)
        )}||${String(bill?.amounts?.additional_G.toFixed(2))}|${String(
          bill?.amounts?.additional_G.toFixed(2)
        )}||||||||||||||||||||||||||||||||||||\n`
      : ""
  }${
    bill?.amounts?.wait_reports! > 0 && bill?.amounts?.wait_reports != undefined
      ? `ITEM|ESPERA COMPRADA|||N|||${String(
          bill?.amounts?.wait_reports.toFixed(2)
        )}||${String(bill?.amounts?.wait_reports.toFixed(2))}|${String(
          bill?.amounts?.wait_reports.toFixed(2)
        )}||||||||||||||||||||||||||||||||||||\n`
      : ""
  }VENCIMIENTO|${moment(bill?.expiration_date.toDate()).format(
    "DD/MM/YY"
  )}|${String(bill?.ImpTotal.toFixed(2))}|
    \n`;

  // preguntar por codigo de caja, BULTOS, PrefijoRendicion
  let blob = new Blob([data], { type: "text/plain;charset=utf-8" });
  fs.saveAs(
    blob,
    "COMPRA" + "-" + bill?.point_of_sale + "-" + bill?.voucher_number + ".txt"
  );
};
