import { ContainerPage, PageTitle, Spinner, Button } from "components/styles";
import firebase from "firebase/app";
import "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import { getZoneConfig, deleteZoneConfig } from "../Global/reducer";
import { FormWithRedux } from "./new";
import { Row, Col, Modal, Alert } from "react-bootstrap";
import { ZoneConfig } from "types/config";
import { useAsyncFunction } from "controllers/hooks";
import { QuizParams } from "types/module-transfer";
import { WithId } from "controllers/database";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";

const ConfigViewZone = () => {
  const { id } = useParams<QuizParams>();
  const [initialValues, setInitialValues] = useState<any>(undefined);
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const history = useHistory();

  const [func, loadingDelete, errorDelete] = useAsyncFunction(
    () => deleteZoneConfig(id),
    undefined,
    () => setDeleted(true)
  );
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [nameZone, setNameZone] = useState("");
  const toggleEdit = () => setDisabled(!disabled);

  useEffect(() => {
    updateData();
  }, []);

  const updateData = () => {
    setLoading(true);

    getZoneConfig(id)
      .then((values) => {
        console.log(values);
        setNameZone(values.name);
        setInitialValues({
          name: values.name,
          km_base: values.km_base,
          price_km_excess: values.price_km_excess,
          price_km_second_excess: values.price_km_second_excess,
          km_long_trip: values.km_long_trip,
          price_km_short_trip: values.price_km_short_trip,
          price_km_long_trip: values.price_km_long_trip,
          price_minute_trip: values.price_minute_trip,
          price_minute_wait: values.price_minute_wait,
          price_minimum_trip: values.price_minimum_trip,
          minimum_radius: values.minimum_radius,
          maximum_distance_origin: values.maximum_distance_origin,
          minimum_wait: values.minimum_wait,
          minimum_salary_short_transfer: values.minimum_salary_short_transfer,
          minimum_salary_long_transfer: values.minimum_salary_long_transfer,
          maximum_price_negative_transfer:
            values.maximum_price_negative_transfer,
          minimum_price_negative_transfer:
            values.minimum_price_negative_transfer,
          minutes_day: values.minutes_day,
          adjustment_pool: values.adjustment_pool,
          additional_percentage_return: values.additional_percentage_return,
          additional_late_rate: values.additional_late_rate,
          additional_night_rate: values.additional_night_rate,
          valid_from: values.valid_from.toDate(),
          polygon: values.points.map((p) => ({
            lat: p.latitude,
            lng: p.longitude,
          })),
        });
      })
      .then(() => setLoading(false));
  };

  const handleSubmit = async (values: any) => {
    const data: ZoneConfig & WithId = {
      km_base: Number(values.km_base),
      price_km_excess: Number(values.price_km_excess),
      price_km_second_excess: Number(values.price_km_second_excess),
      km_long_trip: Number(values.km_long_trip),
      price_km_short_trip: Number(values.price_km_short_trip),
      price_km_long_trip: Number(values.price_km_long_trip),
      price_minute_trip: Number(values.price_minute_trip),
      price_minute_wait: Number(values.price_minute_wait),
      price_minimum_trip: Number(values.price_minimum_trip),
      minimum_radius: Number(values.minimum_radius),
      maximum_distance_origin: Number(values.maximum_distance_origin),
      minimum_wait: Number(values.minimum_wait),
      minimum_salary_short_transfer: Number(
        values.minimum_salary_short_transfer
      ),
      minimum_salary_long_transfer: Number(values.minimum_salary_long_transfer),
      maximum_price_negative_transfer: Number(
        values.maximum_price_negative_transfer
      ),
      minimum_price_negative_transfer: Number(
        values.minimum_price_negative_transfer
      ),
      minutes_day: Number(values.minutes_day),
      name: values.name,
      adjustment_pool: values.adjustment_pool,
      additional_percentage_return: values.additional_percentage_return,
      additional_late_rate: values.additional_late_rate,
      additional_night_rate: values.additional_night_rate,
      valid_from: values.valid_from.toISOString(),
      id: id,
      points: values.polygon.map(
        (p: { lat: number; lng: number }) =>
          new firebase.firestore.GeoPoint(p.lat, p.lng)
      ),
    };
    const type = "zone";
    await CALLABLE_FUNCTIONS.ADMIN_CHANGE_PRICE_SALE({ data, type });
    //return setZoneConfig(data, id);
  };

  if (loading) return <Spinner />;

  if (deleted)
    return (
      <>
        <Alert variant="warning">
          Borrada con éxito.{" "}
          <b>
            <a onClick={() => history.goBack()}>VOLVER</a>
          </b>
        </Alert>
      </>
    );

  return (
    <ContainerPage>
      <PageTitle>Ver/Configurar Zona {nameZone}</PageTitle>
      <Row>
        <Col>
          <Button
            className="pull-right"
            style={{ margin: "0 10px 5px" }}
            variant="danger"
            onClick={() => setDeleteModal(true)}
          >
            Eliminar
          </Button>
          {disabled && (
            <Button
              className="pull-right"
              style={{ margin: "0 10px 5px" }}
              variant="info"
              onClick={toggleEdit}
            >
              Editar
            </Button>
          )}
        </Col>
      </Row>
      <FormWithRedux
        onSubmit={handleSubmit}
        initialValues={initialValues}
        disabled={disabled}
      />

      <Modal show={deleteModal} onHide={() => setDeleteModal(false)} size="lg">
        <Modal.Body>
          {loadingDelete ? (
            <Spinner />
          ) : errorDelete ? (
            errorDelete
          ) : (
            <h6>¿Está seguro que desea eliminar la zona?</h6>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={func}>
            Sí
          </Button>
          <Button variant="secondary" onClick={() => setDeleteModal(false)}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </ContainerPage>
  );
};

export default ConfigViewZone;
