import { Field } from "components/input/field";
import { Padding, Spinner, SubmitButton, AsyncButton } from "components/styles";
import React, { useEffect, useState } from "react";
import { Button, Col, Row, Alert } from "react-bootstrap";
import { reduxForm } from "redux-form";
import { getGlobalConfiguration, setGlobalConfiguration } from "./reducer";
import { GlobalConfig } from "types/config";
import { ConfigContract } from "types/config";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import { FIRESTORE } from "constants/firestore";
import ExcelJS from "exceljs";
import moment from "moment";
import * as fs from "file-saver";
import { FbDate, FinishStatus } from "types/transfer";

const ConfigTab = () => {
  const [initialValues, setInitialValues] = useState<any>(undefined);
  const [loading, setLoading] = useState(true);

  const updateData = () => {
    setLoading(true);
    getGlobalConfiguration()
      .then((values) => {
        setInitialValues({
          km_base: values.km_base,
          km_long_trip: values.km_long_trip,
          price_km_short_trip: values.price_km_short_trip,
          price_km_long_trip: values.price_km_long_trip,
          price_minute_trip: values.price_minute_trip,
          price_km_excess: values.price_km_excess,
          markup_global: values.markup_global,
          price_km_second_excess: values.price_km_second_excess,
          price_km_without_passenger: values.price_km_without_passenger,
          price_minute_wait: values.price_minute_wait,
          price_minimum_trip: values.price_minimum_trip,
          buffer_transfer: values.buffer_transfer,
          minimum_radius: values.minimum_radius,
          maximum_distance_origin: values.maximum_distance_origin,
          minimum_wait: values.minimum_wait,
          minimum_salary_short_transfer: values.minimum_salary_short_transfer,
          minimum_salary_long_transfer: values.minimum_salary_long_transfer,
          maximum_price_negative_transfer:
            values.maximum_price_negative_transfer,
          minimum_price_negative_transfer:
            values.minimum_price_negative_transfer,
          alerts_email: values.alerts_email,
          minutes_day: values.minutes_day,
          adjustment_pool: values.adjustment_pool,
          valid_from: values.valid_from.toDate(),
          value_records: values.value_records,
          driver_text: values.driver_text,
          additional_percentage_return: values.additional_percentage_return,
          additional_late_rate: values.additional_late_rate,
          additional_night_rate: values.additional_night_rate,
        });
      })
      .then(() => setLoading(false));
  };

  useEffect(() => {
    updateData();
  }, []);

  const handleSubmit = async (values: any) => {
    const value_records = initialValues.value_records;
    const index = value_records.length;
    const valid_from = FbDate.fromDate(initialValues.valid_from);
    const valid_to = FbDate.fromDate(
      moment(values.valid_from).subtract(1, "day").toDate()
    );

    const newValueRecord = {
      markup_global: initialValues.markup_global,
      minimum_price_negative_transfer:
        initialValues.maximum_price_negative_transfer,
      valid_from: valid_from,
      valid_to: valid_to,
    };
    value_records.splice(index, 0, newValueRecord);
    console.log(value_records);
    const data: GlobalConfig = {
      km_base: Number(values.km_base),
      km_long_trip: Number(values.km_long_trip),
      price_km_short_trip: Number(values.price_km_short_trip),
      price_km_long_trip: Number(values.price_km_long_trip),
      price_minute_trip: Number(values.price_minute_trip),
      price_km_excess: Number(values.price_km_excess),
      price_km_second_excess: Number(values.price_km_second_excess),
      price_km_without_passenger: Number(values.price_km_without_passenger),
      markup_global: Number(values.markup_global),
      price_minute_wait: Number(values.price_minute_wait),
      price_minimum_trip: Number(values.price_minimum_trip),
      buffer_transfer: Number(values.buffer_transfer),
      minimum_radius: Number(values.minimum_radius),
      maximum_distance_origin: Number(values.maximum_distance_origin),
      minimum_wait: Number(values.minimum_wait),
      minimum_salary_short_transfer: Number(
        values.minimum_salary_short_transfer
      ),
      minimum_salary_long_transfer: Number(values.minimum_salary_long_transfer),
      maximum_price_negative_transfer: Number(
        values.maximum_price_negative_transfer
      ),
      minimum_price_negative_transfer: Number(
        values.minimum_price_negative_transfer
      ),
      alerts_email: values.alerts_email,
      minutes_day: Number(values.minutes_day),
      adjustment_pool: Number(values.adjustment_pool),
      valid_from: FbDate.fromDate(new Date(values.valid_from)),
      value_records: value_records,
      additional_percentage_return: values.additional_percentage_return,
      additional_late_rate: values.additional_late_rate,
      additional_night_rate: values.additional_night_rate,
    };

    const dataContract: ConfigContract = { driver_text: values.driver_text };
    console.log(data);

    await setGlobalConfiguration(data, dataContract);
  };

  if (loading) return <Spinner />;
  if (initialValues === undefined) return <></>;
  return (
    <Padding pad="15px">
      <FormWithRedux onSubmit={handleSubmit} initialValues={initialValues} />
    </Padding>
  );
};

const Form = ({ handleSubmit, submitSucceeded, submitting }: any) => {
  const [disabled, setDisabled] = useState(true);
  const toggleEdit = () => setDisabled(!disabled);
  const backRun = () => CALLABLE_FUNCTIONS.ADMIN_RUN_BACK();
  const recalculate = async () => CALLABLE_FUNCTIONS.ADMIN_CHANGE_PRICE();
  const frontRun = async () => {
    console.log("hola");
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Viajes comprados sin venta");
    worksheet.columns = [
      { header: "Fecha", key: "Fecha", width: 15 },
      { header: "Art", key: "Art", width: 15 },
      { header: "Accident", key: "ArtAccident", width: 10 },
      { header: "Autorización", key: "ArtAuthorization", width: 5 },
      { header: "Id Traslado", key: "IdTraslado", width: 10 },
      { header: "Id", key: "Id", width: 25 },
      { header: "Modulo o Tramo", key: "Type", width: 5 },
      { header: "Finished_Status", key: "Finished_Status", width: 5 },
      { header: "Stage", key: "Stage", width: 5 },
      { header: "Bill", key: "Bill", width: 5 },
      { header: "Amount", key: "Amount", width: 10 },
    ];

    let modules = (
      await FIRESTORE.COLLECTION_MODULES_TRANSFER()
        .where(
          "origin.time",
          ">",
          FbDate.fromDate(moment().subtract(1, "month").toDate())
        )
        .get()
    ).docs
      .map((doc) => ({ ref: doc.ref, data: doc.data() }))
      .filter(
        (t) =>
          t.data.art.name === "Experta SML" && t.data.reject?.status === true
      );
    console.log(modules.length, "modules.length");
    console.log(modules, "modules");

    const arrayAmount: {
      fecha: any;
      art: any;
      accidente: any;
      autorizacion: any;
      idTraslado: any;
      id: any;
      tramo: any;
      finished: any;
      stage: any;
      bill: any;
      amount: any;
    }[] = [];
    await Promise.all(
      modules.map(async (dataModule) => {
        let priceGoing = 0;
        let priceReturn = 0;
        let amountModule = 0;
        if (dataModule.data.transfers.past !== undefined) {
          if (dataModule.data.transfers.past!.going) {
            for (
              let i = 0;
              i < dataModule.data.transfers.past!.going.length;
              i++
            ) {
              const transferData = (
                await dataModule.data.transfers.past!.going[i].get()
              ).data();

              if (
                transferData?.status.finished_status === FinishStatus.Exitoso
              ) {
                priceGoing = Number(transferData.amount);

                let totalAdditons = 0;
                if (transferData.info.amount?.addition) {
                  totalAdditons += transferData.info.amount.addition;
                }
                if (transferData.info.amount?.additional_K) {
                  totalAdditons += transferData.info.amount.additional_K;
                }
                if (transferData.info.amount?.additional_5M) {
                  totalAdditons += transferData.info.amount.additional_5M;
                }
                if (transferData.info.amount?.additional_C) {
                  totalAdditons += transferData.info.amount.additional_C;
                }
                if (transferData.info.amount?.additional_E) {
                  totalAdditons += transferData.info.amount.additional_E;
                }

                priceGoing = priceGoing + totalAdditons;
              }
              if (
                transferData?.status.finished_status === FinishStatus.Negativo
              ) {
                priceGoing = Number(transferData.amount);

                let totalAdditons = 0;
                if (transferData.info.amount?.addition) {
                  totalAdditons += transferData.info.amount.addition;
                }
                if (transferData.info.amount?.additional_K) {
                  totalAdditons += transferData.info.amount.additional_K;
                }
                if (transferData.info.amount?.additional_5M) {
                  totalAdditons += transferData.info.amount.additional_5M;
                }
                if (transferData.info.amount?.additional_C) {
                  totalAdditons += transferData.info.amount.additional_C;
                }
                if (transferData.info.amount?.additional_E) {
                  totalAdditons += transferData.info.amount.additional_E;
                }

                priceGoing = priceGoing + totalAdditons;
              }
            }
          }
          if (dataModule.data.transfers.past!.return) {
            for (
              let i = 0;
              i < dataModule.data.transfers.past!.return.length;
              i++
            ) {
              const transferData = (
                await dataModule.data.transfers.past!.return[i].get()
              ).data();
              if (
                transferData?.status.finished_status === FinishStatus.Exitoso
              ) {
                priceReturn = Number(transferData.amount);

                let totalAdditons = 0;
                if (transferData.info.amount?.addition) {
                  totalAdditons += transferData.info.amount.addition;
                }
                if (transferData.info.amount?.additional_K) {
                  totalAdditons += transferData.info.amount.additional_K;
                }
                if (transferData.info.amount?.additional_5M) {
                  totalAdditons += transferData.info.amount.additional_5M;
                }
                if (transferData.info.amount?.additional_C) {
                  totalAdditons += transferData.info.amount.additional_C;
                }
                if (transferData.info.amount?.additional_E) {
                  totalAdditons += transferData.info.amount.additional_E;
                }

                priceReturn = priceReturn + totalAdditons;
              }
              if (
                transferData?.status.finished_status === FinishStatus.Negativo
              ) {
                priceReturn = Number(transferData.amount);
                let totalAdditons = 0;
                if (transferData.info.amount?.addition) {
                  totalAdditons += transferData.info.amount.addition;
                }
                if (transferData.info.amount?.additional_K) {
                  totalAdditons += transferData.info.amount.additional_K;
                }
                if (transferData.info.amount?.additional_5M) {
                  totalAdditons += transferData.info.amount.additional_5M;
                }
                if (transferData.info.amount?.additional_C) {
                  totalAdditons += transferData.info.amount.additional_C;
                }
                if (transferData.info.amount?.additional_E) {
                  totalAdditons += transferData.info.amount.additional_E;
                }
                priceReturn = priceReturn + totalAdditons;
              }
            }
          }
        } else if (dataModule.data.transfers.past === undefined) {
          priceGoing = 0;
          priceReturn = 0;
        }
        amountModule = Number(priceGoing + priceReturn);
        console.log(amountModule, "amountModule");
        arrayAmount.push({
          fecha: moment(dataModule.data.destination.time.toDate()).format(
            "DD/MM/YYYY"
          ),
          art: dataModule.data.art.name,
          accidente: dataModule.data.art.accident,
          autorizacion: dataModule.data.art.authorization,
          idTraslado: dataModule.data.transfer_id,
          id: dataModule.ref.id,
          tramo: dataModule.data.type,
          finished: dataModule.data.status.finished_status,
          stage: dataModule.data.status.stage,
          bill: dataModule.data.sale?.billed,
          amount: amountModule,
        });
      })
    );
    console.log(arrayAmount.length, "arrayAmount");
    console.log(arrayAmount, "arrayAmount");

    arrayAmount.map((arrayAmount) => {
      worksheet.addRow({
        Fecha: arrayAmount.fecha,
        Art: arrayAmount.art,
        ArtAccident: arrayAmount.accidente,
        ArtAuthorization: arrayAmount.autorizacion,
        idTrIdTraslado: arrayAmount.idTraslado,
        Id: arrayAmount.id,
        Type: arrayAmount.tramo,
        Finished_Status: arrayAmount.finished,
        Stage: arrayAmount.stage,
        Bill: arrayAmount.bill,
        Amount: arrayAmount.amount,
      });
    });

    await workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(blob, "viajes_comprados_sin_venta_Oct23.xlsx");
    });
  };
  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col>
          {disabled ? (
            <Button
              className="pull-right"
              style={{ margin: "0 10px 5px" }}
              variant="info"
              onClick={toggleEdit}
            >
              Editar
            </Button>
          ) : (
            <>
              <Button
                className="pull-right"
                style={{ margin: "0 10px 5px" }}
                variant="info"
                onClick={toggleEdit}
              >
                Deshabilitar
              </Button>

              <SubmitButton
                submitting={submitting}
                className="pull-right"
                style={{ margin: "0 10px 5px" }}
                variant="dark"
              >
                Guardar
              </SubmitButton>
            </>
          )}
        </Col>
      </Row>
      <Row>
        {submitSucceeded && (
          <Alert variant="success" style={{ width: "100%" }}>
            Valores Guardados
          </Alert>
        )}
      </Row>
      <Row>
        <Col xl={2}>
          <Field
            disabled={disabled}
            decimals={2}
            increment={0.1}
            labelinput={<>KM viaje base (en km)</>}
            name="km_base"
            type="number-counter"
          />
        </Col>
        <Col xl={2}>
          <Field
            disabled={disabled}
            decimals={2}
            increment={0.1}
            labelinput={<>KM viaje largo (en km)</>}
            name="km_long_trip"
            type="number-counter"
          />
        </Col>
        <Col xl={2}>
          <Field
            disabled={disabled}
            decimals={2}
            increment={0.1}
            labelinput={<>Valor hasta 10Km (en $)</>}
            name="price_km_short_trip"
            type="number-counter"
          />
        </Col>
        <Col xl={2}>
          <Field
            disabled={disabled}
            decimals={2}
            increment={0.1}
            labelinput={<>Valor KM base viaje largo (en $)</>}
            name="price_km_long_trip"
            type="number-counter"
          />
        </Col>
        <Col xl={2}>
          <Field
            disabled={disabled}
            decimals={2}
            increment={0.1}
            labelinput={<>Excedente 10-25Km (en $)</>}
            name="price_km_excess"
            type="number-counter"
          />
        </Col>
        <Col xl={2}>
          <Field
            disabled={disabled}
            decimals={2}
            increment={0.1}
            labelinput={<>Excedente desde los 25Km (en $)</>}
            name="price_km_second_excess"
            type="number-counter"
          />
        </Col>
      </Row>
      <Row>
        <Col xl={2}>
          <Field
            disabled={disabled}
            decimals={2}
            increment={0.1}
            labelinput={<>Valor minuto base viaje (en $)</>}
            name="price_minute_trip"
            type="number-counter"
          />
        </Col>
        <Col xl={2}>
          <Field
            disabled={disabled}
            decimals={2}
            increment={0.1}
            labelinput={<>Valor minuto base espera (en $)</>}
            name="price_minute_wait"
            type="number-counter"
          />
        </Col>
        <Col xl={2}>
          <Field
            disabled={disabled}
            decimals={2}
            increment={0.1}
            labelinput={<>Valor mínimo traslado (en $)</>}
            name="price_minimum_trip"
            type="number-counter"
          />
        </Col>
        <Col xl={2}>
          <Field
            disabled={disabled}
            decimals={2}
            increment={0.1}
            labelinput={<>Kilómetro muerto (en $)</>}
            name="price_km_without_passenger"
            type="number-counter"
          />
        </Col>
        <Col xl={2}>
          <Field
            disabled={disabled}
            decimals={2}
            increment={0.1}
            labelinput={<>Markup Global</>}
            name="markup_global"
            type="number-counter"
          />
        </Col>
        <Col xl={2}>
          <Field
            disabled={disabled}
            decimals={0}
            increment={1}
            labelinput={<>Tiem buffer del traslado (en min)</>}
            name="buffer_transfer"
            type="number-counter"
          />
        </Col>
        <Col xl={2}>
          <Field
            disabled={disabled}
            decimals={2}
            increment={0.1}
            labelinput={<>Máximo valor negativo</>}
            name="maximum_price_negative_transfer"
            type="number-counter"
          />
        </Col>
        <Col xl={2}>
          <Field
            disabled={disabled}
            decimals={2}
            increment={0.1}
            labelinput={<>Mínimo valor negativo</>}
            name="minimum_price_negative_transfer"
            type="number-counter"
          />
        </Col>
        <Col xl={2}>
          <Field
            disabled={disabled}
            decimals={0}
            increment={1}
            labelinput={<>Radio mín de disparo (km lineal)</>}
            name="minimum_radius"
            type="number-counter"
          />
        </Col>
        <Col xl={2}>
          <Field
            disabled={disabled}
            decimals={0}
            increment={1}
            labelinput={<>Espera mínima (en min)</>}
            name="minimum_wait"
            type="number-counter"
          />
        </Col>
        <Col xl={2}>
          <Field
            disabled={disabled}
            decimals={0}
            increment={1}
            labelinput={<>% Ajuste pool</>}
            name="adjustment_pool"
            type="number-counter"
          />
        </Col>
        <Col xl={2}>
          <Field
            disabled={disabled}
            decimals={0}
            increment={1}
            labelinput={<>Máx. distancia a pto de encuentro(km)</>}
            name="maximum_distance_origin"
            type="number-counter"
          />
        </Col>
        <Col xl={2}>
          <Field
            disabled={disabled}
            decimals={0}
            increment={1}
            labelinput={<>% Ad. Retorno</>}
            name="additional_percentage_return"
            type="number-counter"
          />
        </Col>
        <Col xl={2}>
          <Field
            disabled={disabled}
            decimals={0}
            increment={1}
            labelinput={<>% Ad. Tarifa Tarde</>}
            name="additional_late_rate"
            type="number-counter"
          />
        </Col>
        <Col xl={2}>
          <Field
            disabled={disabled}
            decimals={0}
            increment={1}
            labelinput={<>% Ad. Tarifa Noche</>}
            name="additional_night_rate"
            type="number-counter"
          />
        </Col>
        <Col xl={2}>
          <Field
            disabled={disabled}
            labelinput={<>Valido desde:</>}
            name="valid_from"
            type="date-picker"
          />
        </Col>
        <Col xl={2}>
          <Field
            disabled={disabled}
            labelinput={<>Salario mínimo (traslado corto)</>}
            name="minimum_salary_short_transfer"
            type="number"
          />
        </Col>
        <Col xl={2}>
          <Field
            disabled={disabled}
            labelinput={<>Salario mínimo (traslado largo)</>}
            name="minimum_salary_long_transfer"
            type="number"
          />
        </Col>
        <Col xl={2}>
          <Field
            disabled={disabled}
            labelinput={<>Email para alertas</>}
            name="alerts_email"
            type="text"
          />
        </Col>
        <Col xl={2}>
          <Field
            disabled={disabled}
            labelinput={<>Minutos por día</>}
            name="minutes_day"
            type="number"
          />
        </Col>
        <Col xl={12}>
          <Field
            disabled={disabled}
            labelinput={<>Texto Contrato</>}
            name="driver_text"
            type="textarea"
            as="textarea"
            rows="25"
          />
        </Col>
      </Row>
      {/* <Button onClick={() => backRun()}>.</Button> */}
      <Button onClick={() => frontRun()}>..</Button>
      {/* TESTEADOR */}
      <AsyncButton onClick={() => recalculate()}>Recalcular Precio</AsyncButton>
    </form>
  );
};
const FormWithRedux = reduxForm({
  form: "config-form",
})(Form);

export default ConfigTab;
