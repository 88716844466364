import {
  ConfirmPasswordField,
  EmailField,
  NameField,
  PasswordField,
  PhoneField,
  RegisterButton as RegisterButtonNoStyle,
  SurnameField,
} from "components/input/sign-up/fields";
import { connect } from "components/Login/SignUp/signup-form";
import { Spinner } from "components/styles";
import React from "react";
import { Alert, Button, Col, Form } from "react-bootstrap";
import styled from "styled-components";

let RegisterForm = (props: any) => {
  const {
    error,
    handleSubmit,
    submitting,
  }: { error: any; handleSubmit: any; submitting: Boolean } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Form.Row>
        <Col xs={12} md={6}>
          <NameField />
        </Col>
        <Col>
          <SurnameField />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} md={6}>
          <EmailField />
        </Col>
        <Col>
          <PhoneField />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} md={6}>
          <PasswordField />
        </Col>
        <Col>
          <ConfirmPasswordField />
        </Col>
      </Form.Row>
      <Form.Row>
        {/* <Col xs={12} md={6}>
          <PromoCodeField />
        </Col> */}
        <Col>
          {submitting ? (
            <Button block>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 21,
                }}
              >
                <Spinner style={{}} size="sm" />
              </div>
            </Button>
          ) : (
            <RegisterButton />
          )}
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>{error && <Alert variant="danger">{error}</Alert>}</Col>
      </Form.Row>
    </form>
  );
};

const RegisterButton = styled(RegisterButtonNoStyle).attrs((props: any) => ({
  forwardedAs: Button,
  ...props,
}))`
  width: 100%;
`;

export default connect(RegisterForm);
