import { Button, Padding, Spinner, NoData, H2 } from "components/styles";
import { WithId } from "controllers/database";
import { getFullAddress } from "controllers/map";
import React, { useState, useEffect } from "react";
import { Col, Row, Table as BTable, Form } from "react-bootstrap";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { uidSelector } from "redux/selectors";
import styled from "styled-components";
import { ScheduledTransfer, FbDate, FinishStatus } from "types/transfer";
import moment from "moment";
import { usePaginator } from "util/hooks";
import { FIRESTORE } from "constants/firestore";
import { ExtraAdditionals } from "components/Admin/Transfers/view/unassigned";
import { BillStatus } from "types/billing";
import { DatePickerInput } from "components/input/date-picker";

const UserHomePage = () => {
  const uid = useSelector(uidSelector);
  const [endDate, setEndDate] = useState<Date>(
    moment().endOf("day").add(7, "days").toDate()
  );
  const [showAll, setShowAll] = useState<boolean>(true);
  const [items, loading, Pagination, setCollection] = usePaginator(
    FIRESTORE.COLLECTION_USER_TRANSFERS(uid)
      .where("status.stage", "==", 9)
      .orderBy("origin.time", "desc"),
    50
  );

  useEffect(() => {
    setShowAll(false);
    const start = new Date(endDate);
    const startDate = moment(start).startOf("day");
    setCollection(
      FIRESTORE.COLLECTION_USER_TRANSFERS(uid)
        .where("status.stage", "==", 9)
        .where("origin.time", ">=", FbDate.fromDate(startDate.toDate()))
        .where("origin.time", "<=", FbDate.fromDate(endDate))
        .orderBy("origin.time", "desc")
    );
  }, [endDate]);
  useEffect(() => {
    if (showAll) {
      setCollection(
        FIRESTORE.COLLECTION_USER_TRANSFERS(uid)
          .where("status.stage", "==", 9)
          .orderBy("origin.time", "desc")
      );
    }
  }, [showAll]);
  // const dicDatesToTransfers = useMemo(
  //   () =>
  //     transfers !== undefined
  //       ? _.groupBy(
  //           transfers.filter((transfer) => {
  //             if (
  //               !(
  //                 moment(transfer.destination.time.toDate()) >= startDate &&
  //                 moment(transfer.destination.time.toDate()) <= endDate
  //               )
  //             ) {
  //               return false;
  //             }

  //             if (transfer.status.finished_status != 1) {
  //               if (transfer.sale?.billed_from_driver === 1) {
  //                 if (transfer.sale.bill_info) {
  //                   return true;
  //                 } else {
  //                   return false;
  //                 }
  //               }
  //               return true;
  //             } else {
  //               return false;
  //             }
  //           }),
  //           (v) => moment(v.origin.time.toDate()).format("DD-MM-YYYY")
  //         )
  //       : {},
  //   [transfers]
  // );
  return (
    <Padding pad="5px">
      <Row>
        <Col md={12}>
          <H2>Historial de Traslados</H2>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <Form.Label>Buscar por fecha</Form.Label>
          <DatePickerInput
            value={endDate}
            onChange={setEndDate}
          ></DatePickerInput>
        </Col>
        <Col md={9}>
          <Button onClick={() => setShowAll(true)} style={{ marginTop: "2%" }}>
            Todos los traslados
          </Button>
        </Col>
      </Row>
      {loading ? (
        <Spinner style={{ margin: 20 }} />
      ) : items.length === 0 ? (
        <NoData />
      ) : (
        <Table hover responsive>
          <thead>
            <Tr>
              <th>Fecha Hora</th>
              <th>Siniestro</th>
              <th>Estado</th>
              <th>Facturación</th>
              <th>Origen</th>
              <th>Destino</th>
              <th>Base</th>
              <th>Adicionales</th>
              <th>Total</th>
            </Tr>
          </thead>
          <tbody>
            {items.map((item) =>
              item.sale?.billed_from_driver === 1 &&
              !item.sale.bill_info?.pdf ? null : (
                <tr key={item.id}>
                  <Td>
                    <Moment
                      date={item.origin.time.toDate()}
                      format="DD/MM/YYYY"
                    />{" "}
                    {"\n"}
                    <Moment date={item.origin.time.toDate()} format="HH:mm" />
                    {"-"}
                    <Moment
                      style={{ fontWeight: "bold" }}
                      date={item.destination.time.toDate()}
                      format="HH:mm"
                    />
                  </Td>
                  <Td>
                    {item.art.authorization
                      ? item.art.accident + "-" + item.art.authorization
                      : item.art.accident}
                  </Td>
                  <Td>
                    {item.status.finished_status !== undefined &&
                      FinishStatus[item.status.finished_status]}
                  </Td>
                  <Td>
                    {item.sale?.bill_info?.pdf
                      ? "Facturado"
                      : item.sale?.billed_from_driver === BillStatus["No"]
                      ? "En Revisión"
                      : item.sale?.billed_from_driver ===
                          BillStatus["No facturar"] ||
                        item.status.finished_status ===
                          FinishStatus["Cancelado"]
                      ? "Rechazado"
                      : ""}
                  </Td>
                  <Td>{getFullAddress(item.origin)}</Td>
                  <Td>{getFullAddress(item.destination)}</Td>
                  <Td>${item.amount}</Td>
                  <Td>
                    <ExtraAdditionals
                      info={item.info}
                      challengeType={
                        "challenge" in item && item.challenge?.type
                      }
                      tolls={"tolls" in item && item.tolls}
                      base={item.amount}
                    />
                  </Td>
                  <Td style={{ fontWeight: "bold" }}>
                    {"$" + getTotalAmount(item)}
                  </Td>
                </tr>
              )
            )}
          </tbody>
        </Table>
      )}
      <Pagination />
    </Padding>
  );
};

const getTotalAmount = (transfer: ScheduledTransfer & WithId) => {
  let totalAmount = 0;
  totalAmount += Number(transfer.amount);
  if (transfer.info.amount?.addition)
    totalAmount += transfer.info.amount.addition;
  if (transfer.info.amount?.additional_K)
    totalAmount += transfer.info.amount.additional_K;
  if (transfer.info.amount?.additional_E)
    totalAmount += transfer.info.amount.additional_E;
  if (transfer.info.amount?.additional_C)
    totalAmount += transfer.info.amount.additional_C;
  if (transfer.info.amount?.additional_5M)
    totalAmount += transfer.info.amount.additional_5M;
  if (transfer.info.amount?.additional_R)
    totalAmount += transfer.info.amount.additional_R;
  if (transfer.info.amount?.additional_G)
    totalAmount += transfer.info.amount.additional_G;
  let tolls = 0;
  if (transfer.tolls !== undefined) {
    tolls += transfer.tolls.data
      .map((t) => {
        if (t.status === 1)
          return t.amount_corrected !== undefined
            ? Number(t.amount_corrected)
            : Number(t.amount);
        return 0;
      })
      .reduce((a, b) => a + b, 0);
  }
  totalAmount += tolls;
  return totalAmount;
};

const Tr = styled.tr`
  th {
    vertical-align: middle !important;
  }
`;

const Td = styled.td`
  height: 10px;
`;

const Table = styled(BTable)`
  background-color: #fff;
  /* table-layout: fixed; */
  font-size: 14px;
  thead th {
    color: #909399;
    border-bottom: 0px;
  }
`;

export default UserHomePage;
