import { dbEntry } from "util/meta";
import { FbGeoPoint, FbDate } from "./transfer";

@dbEntry
export class Config {
  km_long_trip!: number;
  price_km_short_trip!: number;
  price_km_long_trip!: number;
  price_minute_trip!: number;
  price_minute_wait!: number;
  price_minimum_trip!: number;
  minimum_radius!: number;

  /** Máxima distancia a origen/destino para levantar a la persona/firmar */
  maximum_distance_origin!: number;
  minimum_wait!: number;

  minimum_salary_short_transfer!: number;
  minimum_salary_long_transfer!: number;
  maximum_price_negative_transfer!: number;
  minutes_day!: number;
}

@dbEntry
export class GlobalConfig {
  markup_global!: number;
  km_long_trip!: number;
  km_base!: number;
  price_km_excess!: number;
  price_km_second_excess!: number;
  /** Kilometro muerto*/
  price_km_without_passenger!: number;
  price_km_short_trip!: number;
  price_km_long_trip!: number;
  price_minute_trip!: number;
  price_minute_wait!: number;
  price_minimum_trip!: number;

  minimum_radius!: number;

  /** Máxima distancia a origen/destino para levantar a la persona/firmar */
  maximum_distance_origin!: number;
  minimum_wait!: number;

  minimum_salary_short_transfer!: number;
  minimum_salary_long_transfer!: number;
  maximum_price_negative_transfer!: number;
  minimum_price_negative_transfer!: number;
  minutes_day!: number;
  //------------------------------------------------

  alerts_email!: string;
  buffer_transfer!: number;
  adjustment_pool!: number;
  valid_from!: FbDate;
  value_records?: ValueRecord[];
  //Porcentaje adicional por retorno
  additional_percentage_return!: number;
  //adicional tarifa tarde
  additional_late_rate!: number;
  //adicional tarifa nocturno
  additional_night_rate!: number;
}
export interface ValueRecord {
  markup_global?: number;
  minimum_price_negative_transfer: number;
  valid_from: FbDate;
  valid_to: FbDate;
}
@dbEntry
export class CustomConfig {
  km_long_trip!: number;
  km_base!: number;
  price_km_excess!: number;
  price_km_second_excess!: number;
  price_km_short_trip!: number;
  price_km_long_trip!: number;
  price_minute_trip!: number;
  price_minute_wait!: number;
  price_minimum_trip!: number;
  minimum_radius!: number;

  /** Máxima distancia a origen/destino para levantar a la persona/firmar */
  maximum_distance_origin!: number;
  minimum_wait!: number;

  minimum_salary_short_transfer!: number;
  minimum_salary_long_transfer!: number;
  maximum_price_negative_transfer!: number;
  minimum_price_negative_transfer!: number;
  minutes_day!: number;
  //------------------------------------------------

  name!: string;
  adjustment_pool!: number;
  //porcentaje adicional Retorno
  additional_percentage_return!: number;
  //adicional tarifa tarde
  additional_late_rate!: number;
  //adicional tarifa nocturno
  additional_night_rate!: number;
  valid_from!: FbDate;
  value_records?: ValueRecord[];
}

@dbEntry
export class ZoneConfig {
  km_long_trip!: number;
  km_base!: number;
  price_km_excess!: number;
  price_km_second_excess!: number;
  price_km_short_trip!: number;
  price_km_long_trip!: number;
  price_minute_trip!: number;
  price_minute_wait!: number;
  price_minimum_trip!: number;
  minimum_radius!: number;

  /** Máxima distancia a origen/destino para levantar a la persona/firmar */
  maximum_distance_origin!: number;
  minimum_wait!: number;
  minimum_salary_short_transfer!: number;
  minimum_salary_long_transfer!: number;
  maximum_price_negative_transfer!: number;
  minimum_price_negative_transfer!: number;
  minutes_day!: number;
  adjustment_pool!: number;
  //porcentaje adicional Retorno
  additional_percentage_return!: number;
  //adicional tarifa tarde
  additional_late_rate!: number;
  //adicional tarifa nocturno
  additional_night_rate!: number;
  valid_from!: FbDate;
  value_records?: ValueRecord[];
  //------------------------------------------------

  name!: string;

  //------------------------------------------------
  /** Puntos del polígono ordenados */
  points!: FbGeoPoint[];
  //geohash!: string <-- habría que agregarlo
}

@dbEntry
export class ConfigContract {
  //Lo separamos para que sea visible a los usuarios
  driver_text!: string;
}

export interface MaxPriceTransfer {
  global?: number;
  provinces?: { [key: string]: number };
  localities?: { [key: string]: number };
  zones?: {
    [key: string]: {
      amount: number;
      points: FbGeoPoint[];
    };
  };
}

export interface Contadores {
  cobranzas: number;
  codigo_conductor: number;
  facturas_compra: number;
  ordenes_de_pago: number;
  ordenes_de_pago_bas: number;
  transfer_contador: number;
  tiempo_feleval: number;
}
