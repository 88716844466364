import { Field } from "components/input/field";
import { ContainerPage, PageTitle, SubmitButton } from "components/styles";
import { required } from "controllers/validations";
import firebase from "firebase/app";
import "firebase/firestore";
import React from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { reduxForm } from "redux-form";
import { addZoneConfig } from "../Global/reducer";
import { ConfigFields } from "../Localities/view";
import "./style.css";
import { ZoneConfig } from "types/config";
import { FbDate } from "types/transfer";

export const requiredPolygon = (v: [] | undefined) =>
  v === undefined || v.length === 0
    ? "Tiene que haber algún polígono"
    : undefined;
const ConfigNewZone = () => {
  const handleSubmit = (values: any) => {
    const data: ZoneConfig = {
      km_base: Number(values.km_base),
      price_km_excess: Number(values.price_km_excess),
      price_km_second_excess: Number(values.price_km_second_excess),
      km_long_trip: Number(values.km_long_trip),
      price_km_short_trip: Number(values.price_km_short_trip),
      price_km_long_trip: Number(values.price_km_long_trip),
      price_minute_trip: Number(values.price_minute_trip),
      price_minute_wait: Number(values.price_minute_wait),
      price_minimum_trip: Number(values.price_minimum_trip),
      minimum_radius: Number(values.minimum_radius),
      maximum_distance_origin: Number(values.maximum_distance_origin),
      minimum_wait: Number(values.minimum_wait),
      minimum_salary_short_transfer: Number(
        values.minimum_salary_short_transfer
      ),
      minimum_salary_long_transfer: Number(values.minimum_salary_long_transfer),
      maximum_price_negative_transfer: Number(
        values.maximum_price_negative_transfer
      ),
      minimum_price_negative_transfer: Number(
        values.minimum_price_negative_transfer
      ),
      minutes_day: Number(values.minutes_day),
      name: values.name,
      adjustment_pool: values.adjustment_pool,
      additional_percentage_return: values.additional_percentage_return,
      additional_late_rate: values.additional_late_rate,
      additional_night_rate: values.additional_night_rate,
      valid_from: FbDate.fromDate(new Date(values.valid_from)),
      points: values.polygon.map(
        (p: { lat: number; lng: number }) =>
          new firebase.firestore.GeoPoint(p.lat, p.lng)
      ),
    };
    console.log(data);

    return addZoneConfig(data);
  };
  return (
    <ContainerPage>
      <PageTitle>Nueva Zona</PageTitle>
      <FormWithRedux onSubmit={handleSubmit} />
    </ContainerPage>
  );
};

const Form = ({
  submitting,
  handleSubmit,
  submitSucceeded,
  disabled = false,
}: any) => {
  if (submitSucceeded)
    return <Alert variant="success">Zona agregada/modificada</Alert>;

  return (
    <form onSubmit={handleSubmit}>
      <NameField disabled={disabled} />
      <ConfigFields disabled={disabled} />
      <Field
        disabled={disabled}
        name="polygon"
        type="polygon-map"
        validate={requiredPolygon}
      />
      <SubmitButton submitting={submitting} disabled={disabled}>
        Guardar
      </SubmitButton>
    </form>
  );
};

export const NameField = ({ disabled = false }: { disabled?: boolean }) => (
  <Row>
    <Col xl={6}>
      <Field
        disabled={disabled}
        labelinput={<>Nombre</>}
        name="name"
        type="text"
        validate={required}
      />
    </Col>
  </Row>
);

export const FormWithRedux = reduxForm<{}, any>({
  form: "config-form",
})(Form);

export default ConfigNewZone;
