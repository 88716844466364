import { Table } from "antd";
import { DatePickerInput } from "components/input/date-picker";
import { Field } from "components/input/field";
import {
  AdminTable,
  Button,
  ButtonWithConfirmation,
  ContainerPage,
  H3,
  Link,
  NoData,
  PageTitle,
  QuestionTooltip,
  Spinner,
  SubmitButton,
} from "components/styles";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import { FIRESTORE } from "constants/firestore";
import ROUTES from "constants/routes";
import { useDatabaseDocref } from "controllers/hooks";
import { required } from "controllers/validations";
import firebase from "firebase/app";
import "firebase/firestore";
import { sumBy } from "lodash";
import moment from "moment";
import { of } from "ramda";
import React, { useEffect, useState } from "react";
import { Alert, Col, Form, Modal, Row } from "react-bootstrap";
import { reduxForm, SubmissionError } from "redux-form";
import { BillStatus } from "types/billing";
import {
  DefinitionsTransferType,
  FbDocument,
  ScheduledTransfer,
} from "types/transfer";
import { RevisionStatus } from "types/user-data";
import { usePaginatorWithUid } from "util/hooks";
import { SelectDriverInput, showID } from ".";
import {
  useSaveSelection,
  useSaveSelectionCustom,
  useSaveSelectionCustomNumber,
} from "../reducer";
import { ArtSelector } from "../Sales/generate-bill";

const getTransfers = (
  artSelected: string | undefined,
  driver: string | undefined,
  startDate: Date,
  endDate: Date,
  keyword: string | undefined
) => {
  let coll;
  if (driver) coll = FIRESTORE.COLLECTION_USER_TRANSFERS(driver);
  else coll = FIRESTORE.COLLECTION_TRANSFERS();

  coll = coll
    .where("destination.time", ">=", startDate)
    .where("destination.time", "<=", endDate);

  if (artSelected) coll = coll.where("art.id", "==", artSelected);

  if (keyword) coll = coll.where("keywords", "array-contains", keyword);

  return coll
    .where("sale.billed_from_driver", "in", [
      BillStatus.No,
      BillStatus["Error al facturar"],
      BillStatus["Sin factura Cabify"],
      BillStatus["Facturando"],
    ])
    .orderBy("destination.time", "desc")
    .orderBy("origin.time", "desc")
    .orderBy("destination.geohash", "desc");
};

const GenerateBill = () => {
  const [artSelected, setArtSelected] = useState<string | undefined>(undefined);
  const [keyword, setKeyword] = useState<string | undefined>(undefined);

  const [selected, setSelected] = useSaveSelection("generate-bill");
  const [monto, setMonto] = useSaveSelectionCustomNumber("montos");
  console.log(monto);
  const [driver, setDriver] = useState<string | undefined>(undefined);
  const fecha = new Date(
    Date.UTC(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    )
  );
  let diaDeLaSemana = fecha.getUTCDay();
  let lunesAnterior = 7 + diaDeLaSemana - 1;
  const [startDate, setStartDate] = useState<Date>(
    moment().subtract(lunesAnterior, "days").toDate()
  );
  const [endDate, setEndDate] = useState<Date>(
    moment().subtract(diaDeLaSemana, "day").toDate()
  );

  const [data, loading, Pagination, setCol] =
    usePaginatorWithUid<ScheduledTransfer>(
      getTransfers(artSelected, driver, startDate, endDate, undefined)
    );
  console.log(data, "<-data!");

  const [billModal, setBillModal] = useState(false);
  useEffect(() => {
    setCol(getTransfers(artSelected, driver, startDate, endDate, keyword));
  }, [artSelected, driver, startDate, endDate, keyword]);

  const calculateAmountTolls = (dataTransfer: ScheduledTransfer) => {
    let tolls = 0;
    if (dataTransfer.tolls !== undefined)
      tolls += dataTransfer.tolls.data
        .map((t) => {
          if (t.status === RevisionStatus.Aprobado)
            return t.amount_corrected !== undefined
              ? Number(t.amount_corrected)
              : Number(t.amount);
          return 0;
        })
        .reduce((a, b) => a + b, 0);
    return tolls;
  };
  const amountAdditional = (dataTransfer: ScheduledTransfer) => {
    console.log(dataTransfer, "dataTransfer");

    let amount = 0;
    amount =
      (dataTransfer.info.amount?.additional_5M
        ? dataTransfer.info.amount!.additional_5M
        : 0) +
      (dataTransfer.info.amount?.additional_C
        ? dataTransfer.info.amount?.additional_C
        : 0) +
      (dataTransfer.info.amount?.additional_K
        ? dataTransfer.info.amount?.additional_K
        : 0) +
      (dataTransfer.info.amount?.additional_E
        ? dataTransfer.info.amount?.additional_E
        : 0) +
      (dataTransfer.info.amount?.additional_G
        ? dataTransfer.info.amount?.additional_G
        : 0) +
      (dataTransfer.info.amount?.addition
        ? dataTransfer.info.amount?.addition
        : 0) +
      (dataTransfer.info.amount?.additional_R
        ? dataTransfer.info.amount?.additional_R
        : 0);

    return amount;
  };

  const sumatoria = (montos: any[]) => {
    if (monto) {
      let total = 0;
      for (let i = 0; i < montos.length; i++) {
        total += montos[i];
      }
      return total;
    } else return 0;
  };
  const onSubmit = async (values: any) => {
    const transfers = [...selected]
      .map((v) => firebase.firestore().doc(v))
      .map((v) => ({ uid: v.parent.parent!.id, id: v.id }));
    console.log({
      uidDriver: transfers[0].uid,
      idTransfers: transfers.map((v) => v.id),
      pdf: values.pdf,
      CAEFchVto: values.caefchvto.toISOString(),
      voucher_number: values.voucher_number,
      point_of_sale: values.point_of_sale,
      creation_date: values.creation_date,
    });
    await CALLABLE_FUNCTIONS.MANUAL_DRIVER_BILL({
      uidDriver: transfers[0].uid,
      idTransfers: transfers.map((v) => v.id),
      pdf: values.pdf,
      CAEFchVto: values.caefchvto.toISOString(),
      voucher_number: values.voucher_number,
      point_of_sale: values.point_of_sale,
      creation_date: values.creation_date.toISOString(),
    }).catch((e) => {
      throw new SubmissionError({ _error: e });
    });

    setSelected(new Set());
    setMonto([]);
  };

  const forceBillTransfers = async () => {
    const transfers = [...selected]
      .map((v) => firebase.firestore().doc(v))
      .map((v) => ({ uid: v.parent.parent!.id, id: v.id }));
    await CALLABLE_FUNCTIONS.ADMIN_FORCE_MULTIPLE_DRIVER_BILLS({
      transfers: transfers.map((v) => ({ uidDriver: v.uid, idTransfer: v.id })),
      allInOneBill: true,
    });
    setSelected(new Set());
  };
  if (!data) return <NoData />;

  return (
    <ContainerPage>
      <PageTitle>Generar Facturas Compra</PageTitle>
      <Row>
        <Col>
          <ArtSelector
            artSelected={artSelected}
            setArtSelected={setArtSelected}
          />
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Desde</Form.Label>
            <DatePickerInput value={startDate} onChange={setStartDate} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Hasta (Inclusive)</Form.Label>
            <DatePickerInput value={endDate} onChange={setEndDate} />
          </Form.Group>
        </Col>
        <Col style={{ marginTop: 32 }}>
          <SelectDriverInput onChange={setDriver} value={driver} />
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>
              Buscador{" "}
              <QuestionTooltip
                text={
                  <>
                    Siniestro
                    <br />
                    NPA
                    <br />
                    Cod.Aut
                  </>
                }
              />
            </Form.Label>
            <Form.Control
              value={keyword}
              onChange={(v) => setKeyword(v.target.value)}
            />
          </Form.Group>
        </Col>
      </Row>

      {loading ? (
        <Spinner />
      ) : (
        <Table
          pagination={false}
          columns={[
            {
              title: "Siniestro-Aut/NPA",
              dataIndex: "id",
            },
            {
              title: "Fecha",
              dataIndex: "fecha",
            },
            {
              title: "Modo",
              dataIndex: "type",
            },
            {
              title: "Importe",
              dataIndex: "importe",
            },
            {
              title: "Paciente",
              dataIndex: "passenger",
            },
            {
              title: "Ver",
              dataIndex: "view",
            },
            {
              title: "Agregar",
              dataIndex: "add",
            },
          ]}
          dataSource={data.map((m, index) => ({
            key: index,
            id: showID(m),
            fecha: moment(m.destination.time.toDate()).format("DD/MM/YY HH:mm"),
            // importe: "$" + m.amount,
            importe: (
              calculateAmountTolls(m) +
              m.amount +
              amountAdditional(m)
            ).toFixed(2),
            type: DefinitionsTransferType[m.type],
            passenger: m.passenger.name,
            view: (
              <Link to={ROUTES.ADMIN_MODULE_TRANSFER_VIEW(m.module.id)}>
                <Button variant="light">Ver Traslado</Button>
              </Link>
            ),
            add: !selected.has(
              FIRESTORE.DOCUMENT_SCHEDULED_TRANSFER(m.uid, m.id).path
            ) ? (
              <Button
                onClick={() => {
                  setSelected(
                    new Set([
                      ...selected,
                      FIRESTORE.DOCUMENT_SCHEDULED_TRANSFER(m.uid, m.id).path,
                    ])
                  );
                  const y = [...monto];
                  y.push(
                    Number(
                      (
                        calculateAmountTolls(m) +
                        m.amount +
                        amountAdditional(m)
                      ).toFixed(2)
                    )
                  );
                  setMonto(y);
                }}
              >
                Agregar
              </Button>
            ) : (
              <Button
                variant="danger"
                onClick={() => {
                  const z = new Set(selected);
                  z.delete(
                    FIRESTORE.DOCUMENT_SCHEDULED_TRANSFER(m.uid, m.id).path
                  );
                  setSelected(z);
                  const y = [...monto];
                  y.splice(
                    y.indexOf(
                      Number(
                        (
                          calculateAmountTolls(m) +
                          m.amount +
                          +amountAdditional(m)
                        ).toFixed(2)
                      )
                    ),
                    1
                  );
                  setMonto(y);
                }}
              >
                Quitar
              </Button>
            ),
          }))}
        />
      )}
      <Pagination style={{ marginTop: 10 }} />

      {selected || monto ? (
        <Button
          variant="danger"
          onClick={() => {
            setSelected(new Set());
            setMonto([]);
          }}
        >
          Quitar todos
        </Button>
      ) : (
        <Button disabled>Quitar todos</Button>
      )}

      <H3>{`Monto Total $${sumatoria(monto).toFixed(2)}`}</H3>
      <H3>Traslados seleccionados</H3>

      <AdminTable responsive>
        <thead>
          <tr>
            <th>Siniestro-Aut/NPA</th>
            <th>Fecha Traslado</th>
            <th>Origen</th>
            <th>Destino</th>
            <th>Importe</th>
            <th>Ver Traslado</th>
            {/* <th>Acciones</th> */}
          </tr>
        </thead>
        <tbody>
          {[...selected].map((v) => (
            <RowTransfer
              path={v}
              remove={() => {
                const z = new Set(selected);
                z.delete(v);
                setSelected(z);
                const y = [...monto];
                y.splice(y.indexOf(v.amount), 1);
                setMonto(y);
              }}
            />
          ))}
        </tbody>
      </AdminTable>

      <Row>
        <Col>
          <ButtonWithConfirmation
            className="pull-right"
            variant="danger"
            style={{ marginLeft: 5 }}
            onClick={forceBillTransfers}
            confirmText="¿Está seguro que quiere generar la factura de los traslados seleccionados?"
          >
            Generar Factura automática
          </ButtonWithConfirmation>
          <Button
            className="pull-right"
            variant="danger"
            style={{ marginLeft: 5 }}
            onClick={() => setBillModal(true)}
          >
            Ingresar Factura Manual
          </Button>
        </Col>
      </Row>

      <Modal show={billModal} onHide={() => setBillModal(false)} size="lg">
        <Modal.Header closeButton>Facturación</Modal.Header>
        <Modal.Body>
          <FormGenerateBillRedux onSubmit={onSubmit} />
        </Modal.Body>
      </Modal>
    </ContainerPage>
  );
};

const RowTransfer = ({
  path,
  remove,
}: {
  path: string;
  remove: () => void;
}) => {
  const id = firebase.firestore().doc(path).id;
  const transfer = useDatabaseDocref<ScheduledTransfer>(
    firebase.firestore().doc(path) as FbDocument<ScheduledTransfer>
  );
  let totalTolls = 0;
  if (transfer) {
    if (transfer!.tolls) {
      for (let j = 0; j < transfer!.tolls.data.length; j++) {
        const tollAmount = transfer!.tolls!.data[j].amount_corrected
          ? Number(transfer!.tolls.data[j].amount_corrected)
          : Number(transfer!.tolls.data[j].amount);
        totalTolls = totalTolls + tollAmount;
      }
    }
  }

  let additionalAmount = 0;
  if (transfer) {
    additionalAmount =
      (transfer!.info.amount?.additional_5M
        ? transfer!.info.amount?.additional_5M
        : 0) +
      (transfer!.info.amount?.additional_C
        ? transfer!.info.amount?.additional_C
        : 0) +
      (transfer!.info.amount?.additional_K
        ? transfer!.info.amount?.additional_K
        : 0) +
      (transfer!.info.amount?.additional_E
        ? transfer!.info.amount?.additional_E
        : 0);
  }

  if (!transfer) return <></>;
  return (
    <tr>
      <td>{showID({ id, ...transfer })}</td>
      <td>
        {moment(transfer.destination.time.toDate()).format("DD/MM/YY HH:mm")}
      </td>

      <td>{transfer.origin.address.formatted_address}</td>
      <td>{transfer.destination.address.formatted_address}</td>
      <td>${transfer.amount + totalTolls + additionalAmount}</td>

      <td>
        <Link to={ROUTES.ADMIN_MODULE_TRANSFER_VIEW(transfer.module.id)}>
          <Button variant="light">Ver Traslado</Button>
        </Link>
      </td>
      <td>
        {/* <Button onClick={remove} variant="light">
          REMOVER SELECCIÓN
        </Button> */}
      </td>
    </tr>
  );
};

const FormGenerateBill = ({
  handleSubmit,
  submitting,
  error,
  submitSucceeded,
}: any) => {
  if (submitting) return <Spinner />;
  return (
    <form onSubmit={handleSubmit}>
      <Row>{error && <Alert variant="danger">{error.message}</Alert>}</Row>
      <Row>
        {submitSucceeded && (
          <Alert variant="success" style={{ width: "100%" }}>
            Valores Guardados
          </Alert>
        )}
      </Row>
      <Row>
        <Col>
          <Field
            labelinput={<>Fecha Vencimiento Cae</>}
            name="caefchvto"
            type="date-picker"
            validate={required}
          />
        </Col>
        <Col>
          <Field
            labelinput={<>Fecha de Creación</>}
            name="creation_date"
            type="date-picker"
            validate={required}
          />
        </Col>
        <Col>
          <Field
            labelinput={<>Número Talonario / Pto de Venta</>}
            name="point_of_sale"
            type="text"
            validate={[required, requiredPtoVenta]}
          />
        </Col>
        <Col>
          <Field
            labelinput={<>Número Factura/Voucher</>}
            name="voucher_number"
            type="text"
            validate={[required, requiredVoucher]}
          />
        </Col>
        <Col>
          <Field
            labelinput={<>PDF Factura</>}
            name="pdf"
            type="file"
            validate={required}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <SubmitButton block submitting={submitting}>
            Enviar
          </SubmitButton>
        </Col>
      </Row>
    </form>
  );
};
const FormGenerateBillRedux = reduxForm({
  form: "bill-transfer",
})(FormGenerateBill);

export default GenerateBill;

export const requiredPtoVenta = (value: any) =>
  value.length > 5 ? "No puede tener mas de 5 caracteres." : undefined;

export const requiredVoucher = (value: any) =>
  value.length > 8 ? "No puede tener mas de 8 caracteres." : undefined;
