import { ContainerPage, PageTitle, Button, Spinner } from "components/styles";
import React, { useEffect, useState } from "react";
import { getProvinceConfig, setProvinceConfig } from "../Global/reducer";
import { FormWithRedux as FormCustomConfig } from "../Localities/view";
import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router";
import { CustomConfig } from "types/config";
import { QuizParams } from "types/module-transfer";
import moment from "moment";
import { FbDate } from "types/transfer";
import { NameField, FormWithRedux } from "./new";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";

const ConfigViewProvince = () => {
  const { id } = useParams<QuizParams>();
  const [initialValues, setInitialValues] = useState<any>(undefined);
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const toggleEdit = () => setDisabled(!disabled);

  /*const updateData = () => {
        setLoading(true)
        getConfigProvince('0').then( setItem ).then(() => setLoading(false))
    }*/
  const updateData = () => {
    setLoading(true);
    getProvinceConfig(id)
      .then((values) => {
        setInitialValues({
          name: values.name,
          km_long_trip: values.km_long_trip,
          price_km_excess: values.price_km_excess,
          price_km_second_excess: values.price_km_second_excess,
          price_km_short_trip: values.price_km_short_trip,
          price_km_long_trip: values.price_km_long_trip,
          price_minute_trip: values.price_minute_trip,
          price_minute_wait: values.price_minute_wait,
          price_minimum_trip: values.price_minimum_trip,
          minimum_radius: values.minimum_radius,
          maximum_distance_origin: values.maximum_distance_origin,
          minimum_wait: values.minimum_wait,
          minimum_salary_short_transfer: values.minimum_salary_short_transfer,
          minimum_salary_long_transfer: values.minimum_salary_long_transfer,
          maximum_price_negative_transfer:
            values.maximum_price_negative_transfer,
          minimum_price_negative_transfer:
            values.minimum_price_negative_transfer,
          minutes_day: values.minutes_day,
          adjustment_pool: values.adjustment_pool,
          additional_percentage_return: values.additional_percentage_return,
          additional_late_rate: values.additional_late_rate,
          additional_night_rate: values.additional_night_rate,
          valid_from: values.valid_from.toDate(),
        });
      })
      .then(() => setLoading(false));
  };

  useEffect(() => {
    updateData();
  }, []);

  const handleSubmit = async (values: any) => {
    const data: any = {
      km_base: Number(values.km_base),
      price_km_excess: Number(values.price_km_excess),
      price_km_second_excess: Number(values.price_km_second_excess),
      km_long_trip: Number(values.km_long_trip),
      price_km_short_trip: Number(values.price_km_short_trip),
      price_km_long_trip: Number(values.price_km_long_trip),
      price_minute_trip: Number(values.price_minute_trip),
      price_minute_wait: Number(values.price_minute_wait),
      price_minimum_trip: Number(values.price_minimum_trip),
      minimum_radius: Number(values.minimum_radius),
      maximum_distance_origin: Number(values.maximum_distance_origin),
      minimum_wait: Number(values.minimum_wait),
      minimum_salary_short_transfer: Number(
        values.minimum_salary_short_transfer
      ),
      minimum_salary_long_transfer: Number(values.minimum_salary_long_transfer),
      maximum_price_negative_transfer: Number(
        values.maximum_price_negative_transfer
      ),
      minimum_price_negative_transfer: Number(
        values.minimum_price_negative_transfer
      ),
      minutes_day: Number(values.minutes_day),
      adjustment_pool: values.adjustment_pool,
      additional_percentage_return: values.additional_percentage_return,
      additional_late_rate: values.additional_late_rate,
      additional_night_rate: values.additional_night_rate,
      name: values.name,
      valid_from: values.valid_from.toISOString(),
      id: id,
    };
    const type = "province";
    console.log(data);
    await CALLABLE_FUNCTIONS.ADMIN_CHANGE_PRICE_SALE({ data, type });
    //return setProvinceConfig(data, id);
  };

  if (loading) return <Spinner />;
  return (
    <ContainerPage>
      <PageTitle>Ver/Editar Configuración Provincia</PageTitle>
      <Row>
        <Col>
          {disabled ? (
            <Button
              className="pull-right"
              style={{ margin: "0 10px 5px" }}
              variant="info"
              onClick={toggleEdit}
            >
              Editar
            </Button>
          ) : null}
        </Col>
      </Row>
      <FormWithRedux onSubmit={handleSubmit} initialValues={initialValues} />
    </ContainerPage>
  );
};
export default ConfigViewProvince;
