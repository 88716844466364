import { Field } from "components/input/field";
import {
  ContainerPage,
  Spinner,
  PageTitle,
  SubmitButton,
} from "components/styles";
import React, { useEffect, useState } from "react";
import { Button, Col, Row, Alert } from "react-bootstrap";
import { reduxForm } from "redux-form";
import { getLocalityConfig, setLocalityConfig } from "../Global/reducer";
import { required } from "controllers/validations";
import { NameField } from "./new";
import { useParams } from "react-router";
import { QuizParams } from "types/module-transfer";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";

const ConfigViewLocality = () => {
  const { id } = useParams<QuizParams>();
  const [initialValues, setInitialValues] = useState<any>(undefined);
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const toggleEdit = () => setDisabled(!disabled);

  const updateData = () => {
    setLoading(true);
    getLocalityConfig(id)
      .then((values) => {
        setInitialValues({
          name: values.name,
          km_long_trip: values.km_long_trip,
          price_km_short_trip: values.price_km_short_trip,
          price_km_long_trip: values.price_km_long_trip,
          price_minute_trip: values.price_minute_trip,
          price_km_excess: values.price_km_excess,
          price_km_second_excess: values.price_km_second_excess,
          price_minute_wait: values.price_minute_wait,
          price_minimum_trip: values.price_minimum_trip,
          minimum_radius: values.minimum_radius,
          maximum_distance_origin: values.maximum_distance_origin,
          minimum_wait: values.minimum_wait,
          minimum_salary_short_transfer: values.minimum_salary_short_transfer,
          minimum_salary_long_transfer: values.minimum_salary_long_transfer,
          maximum_price_negative_transfer:
            values.maximum_price_negative_transfer,
          minimum_price_negative_transfer:
            values.minimum_price_negative_transfer,
          additional_percentage_return: values.additional_percentage_return,
          additional_late_rate: values.additional_late_rate,
          additional_night_rate: values.additional_night_rate,
          minutes_day: values.minutes_day,
          valid_from: values.valid_from.toDate(),
        });
      })
      .then(() => setLoading(false));
  };

  useEffect(() => {
    updateData();
  }, []);

  const handleSubmit = async (values: any) => {
    const data: any = {
      km_base: Number(values.km_base),
      price_km_excess: Number(values.price_km_excess),
      price_km_second_excess: Number(values.price_km_second_excess),
      km_long_trip: Number(values.km_long_trip),
      price_km_short_trip: Number(values.price_km_short_trip),
      price_km_long_trip: Number(values.price_km_long_trip),
      price_minute_trip: Number(values.price_minute_trip),
      price_minute_wait: Number(values.price_minute_wait),
      price_minimum_trip: Number(values.price_minimum_trip),
      minimum_radius: Number(values.minimum_radius),
      maximum_distance_origin: Number(values.maximum_distance_origin),
      minimum_wait: Number(values.minimum_wait),
      minimum_salary_short_transfer: Number(
        values.minimum_salary_short_transfer
      ),
      minimum_salary_long_transfer: Number(values.minimum_salary_long_transfer),
      maximum_price_negative_transfer: Number(
        values.maximum_price_negative_transfer
      ),
      minimum_price_negative_transfer: Number(
        values.minimum_price_negative_transfer
      ),
      minutes_day: Number(values.minutes_day),
      additional_percentage_return: values.additional_percentage_return,
      additional_late_rate: values.additional_late_rate,
      additional_night_rate: values.additional_night_rate,
      name: values.name,
      valid_from: values.valid_from.toISOString(),
      id: id,
    };
    const type = "locality";
    await CALLABLE_FUNCTIONS.ADMIN_CHANGE_PRICE_SALE({ data, type });
    //return setLocalityConfig(data, id);
  };

  if (loading) return <Spinner />;
  return (
    <ContainerPage>
      <PageTitle>Ver/Editar Configuración Localidad</PageTitle>
      <Row>
        <Col>
          {disabled ? (
            <Button
              className="pull-right"
              style={{ margin: "0 10px 5px" }}
              variant="info"
              onClick={toggleEdit}
            >
              Editar
            </Button>
          ) : null}
        </Col>
      </Row>
      <FormWithRedux initialValues={initialValues} onSubmit={handleSubmit} />
    </ContainerPage>
  );
};

const Form = ({
  submitting,
  handleSubmit,
  submitSucceeded,
  disabled = false,
}: any) => {
  if (submitSucceeded)
    return <Alert variant="success">Localidad agregada/modificada</Alert>;
  return (
    <form onSubmit={handleSubmit}>
      <NameField disabled={disabled} />
      <ConfigFields disabled={disabled} />
      <SubmitButton submitting={submitting} disabled={disabled}>
        Guardar
      </SubmitButton>
    </form>
  );
};

export const ConfigFields = ({ disabled = false }: { disabled?: boolean }) => (
  <>
    <Row>
      <Col xl={2}>
        <Field
          disabled={disabled}
          decimals={2}
          increment={0.1}
          labelinput={<>KM viaje base (en km)</>}
          name="km_base"
          type="number-counter"
        />
      </Col>
      <Col xl={2}>
        <Field
          disabled={disabled}
          decimals={2}
          increment={0.1}
          labelinput={<>KM viaje largo (en km)</>}
          name="km_long_trip"
          type="number-counter"
        />
      </Col>
      <Col xl={2}>
        <Field
          disabled={disabled}
          decimals={2}
          increment={0.1}
          labelinput={<>Valor hasta 10Km (en $)</>}
          name="price_km_short_trip"
          type="number-counter"
        />
      </Col>
      <Col xl={2}>
        <Field
          disabled={disabled}
          decimals={2}
          increment={0.1}
          labelinput={<>Valor KM base viaje largo (en $)</>}
          name="price_km_long_trip"
          type="number-counter"
        />
      </Col>
      <Col xl={2}>
        <Field
          disabled={disabled}
          decimals={2}
          increment={0.1}
          labelinput={<>Valor km entre 10 y 25 Km (en $)</>}
          name="price_km_excess"
          type="number-counter"
        />
      </Col>
      <Col xl={2}>
        <Field
          disabled={disabled}
          decimals={2}
          increment={0.1}
          labelinput={<>Valor Km mayor a 25 Km (en $)</>}
          name="price_km_second_excess"
          type="number-counter"
        />
      </Col>
    </Row>
    <Row>
      <Col xl={2}>
        <Field
          disabled={disabled}
          decimals={2}
          increment={0.1}
          labelinput={<>Valor minuto base viaje (en $)</>}
          name="price_minute_trip"
          type="number-counter"
          validate={required}
        />
      </Col>
      <Col xl={2}>
        <Field
          disabled={disabled}
          decimals={2}
          increment={0.1}
          labelinput={<>Valor minuto base espera (en $)</>}
          name="price_minute_wait"
          type="number-counter"
          validate={required}
        />
      </Col>
      <Col xl={2}>
        <Field
          disabled={disabled}
          decimals={2}
          increment={0.1}
          labelinput={<>Valor mínimo traslado (en $)</>}
          name="price_minimum_trip"
          type="number-counter"
          validate={required}
        />
      </Col>

      <Col xl={2}>
        <Field
          disabled={disabled}
          decimals={0}
          increment={1}
          labelinput={<>Radio mínimo de disparo (en km)</>}
          name="minimum_radius"
          type="number-counter"
          validate={required}
        />
      </Col>

      <Col xl={2}>
        <Field
          disabled={disabled}
          decimals={0}
          increment={1}
          labelinput={<>Máx. distancia a punto de encuentro (en km)</>}
          name="maximum_distance_origin"
          type="number-counter"
          validate={required}
        />
      </Col>

      <Col xl={2}>
        <Field
          disabled={disabled}
          decimals={0}
          increment={1}
          labelinput={<>Espera mínima (en min)</>}
          name="minimum_wait"
          type="number-counter"
          validate={required}
        />
      </Col>
      <Col xl={2}>
        <Field
          disabled={disabled}
          decimals={2}
          increment={0.1}
          labelinput={<>Máximo valor negativo</>}
          name="maximum_price_negative_transfer"
          type="number-counter"
          validate={required}
        />
      </Col>
      <Col xl={2}>
        <Field
          disabled={disabled}
          decimals={2}
          increment={0.1}
          labelinput={<>Máximo valor negativo</>}
          name="minimum_price_negative_transfer"
          type="number-counter"
          validate={required}
        />
      </Col>
      <Col xl={2}>
        <Field
          disabled={disabled}
          decimals={2}
          increment={0.1}
          labelinput={<>Minutos por día</>}
          name="minutes_day"
          type="number-counter"
          validate={required}
        />
      </Col>
      <Col xl={2}>
        <Field
          disabled={disabled}
          decimals={0}
          increment={1}
          labelinput={<>% Ajuste pool</>}
          name="adjustment_pool"
          type="number-counter"
        />
      </Col>
      <Col xl={2}>
        <Field
          disabled={disabled}
          decimals={0}
          increment={1}
          labelinput={<>% Ad. Retorno</>}
          name="additional_percentage_return"
          type="number-counter"
        />
      </Col>
      <Col xl={2}>
        <Field
          disabled={disabled}
          decimals={0}
          increment={1}
          labelinput={<>% Ad. Tarifa Tarde</>}
          name="additional_late_rate"
          type="number-counter"
        />
      </Col>
      <Col xl={2}>
        <Field
          disabled={disabled}
          decimals={0}
          increment={1}
          labelinput={<>% Ad. Tarifa Noche</>}
          name="additional_night_rate"
          type="number-counter"
        />
      </Col>
      <Col xl={4}>
        <Field
          disabled={disabled}
          labelinput={<>Salario mínimo (traslado corto)</>}
          name="minimum_salary_short_transfer"
          type="number"
          validate={required}
        />
      </Col>
      <Col xl={4}>
        <Field
          disabled={disabled}
          labelinput={<>Salario mínimo (traslado largo)</>}
          name="minimum_salary_long_transfer"
          type="number"
          validate={required}
        />
      </Col>
      <Col xl={2}>
        <Field
          disabled={disabled}
          labelinput={<>Valido desde:</>}
          name="valid_from"
          type="date-picker"
        />
      </Col>
    </Row>
  </>
);

export const FormWithRedux = reduxForm<{}, any>({
  form: "config-form",
})(Form);

export default ConfigViewLocality;
