import { required, minkLength } from "components/Login/SignUp/validations";
import {
  ReduxDecorator,
  ReduxValidationDecorator,
} from "components/bootstrap-redux";
import React from "react";
import { Field, Fields } from "redux-form";
import PhoneInput from "components/input/phone";
import { Alert, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import ROUTES from "constants/routes";
import { FormControl } from "components/styles";

const FieldInputValidationFormControl = ReduxValidationDecorator(FormControl); //fix defocus al primer caracter
const FieldInputFormControl = ReduxDecorator(FormControl);
const min8Length = minkLength(8);
const min6Length = minkLength(6);
const min7Length = minkLength(7);

export const NameField = ({
  placeholder = "Tu nombre",
  value,
}: {
  placeholder?: string;
  value?: string;
}) => (
  <Field
    component={FieldInputValidationFormControl}
    name="name"
    type="text"
    value={value}
    placeholder={placeholder}
    validate={required}
  />
);

export const SurnameField = ({ placeholder = "Tu apellido" }: any) => (
  <Field
    component={FieldInputValidationFormControl}
    name="surname"
    type="text"
    placeholder={placeholder}
    validate={required}
  />
);

export const PhoneField = (props: any) => (
  <Fields
    component={PhoneInput}
    names={["phone.area_code", "phone.number"]}
    validate={(value, allValues, props, name) =>
      (name === "phone.number" && min7Length(value)) ||
      (name === "phone.area_code" && undefined)
    }
    {...props}
  />
);

export const EmailField = (props: any) => (
  <Field
    component={FieldInputValidationFormControl}
    name="email"
    type="email"
    id="txt_email"
    placeholder="Tu email"
    validate={required}
  />
);

/* export const PromoCodeField = (props: any) => (
  <Field
    component={FieldInputFormControl}
    className="col"
    name="promo_code"
    type="text"
    placeholder="Cód. Promocional"
  />
); */

export const PasswordField = (props: any) => (
  <Field
    component={FieldInputValidationFormControl}
    name="password"
    type="password"
    placeholder="Tu contraseña"
    validate={[required, min6Length]}
  />
);

export const ConfirmPasswordField = (props: any) => (
  <Field
    component={FieldInputValidationFormControl}
    name="confirm_password"
    type="password"
    id="txt_confirm_password"
    placeholder="Repetí tu contraseña"
  />
);

export const ErrorFeedback = ({ error }: any) => {
  return (error && <Alert variant="danger">{error}</Alert>) || null;
};

export const PrivacyLink = (props: any) => (
  <p {...props}>
    Al registrarte aceptas nuestra{" "}
    <Link to={ROUTES.PRIVACY} target="_blank">
      politica de privacidad
    </Link>
  </p>
);

export const RegisterButton = ({
  pristine,
  submitting,
  as = Button,
  ...props
}: any) =>
  React.createElement(
    as,
    {
      type: "submit",
      disabled: pristine || submitting,
      label: "Registrarme",
      ...props,
    },
    "Registrarme"
  );
